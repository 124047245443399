.c-expander {

  $self: &;

  padding-bottom: 40px;

  &__body {
    display: none;
    padding-top: 40px;
  }

  &--open {
    #{ $self }__heading {
      background-color: $aqua-haze;
      color: $teal;
    }
    #{ $self }__body {
      display: block;
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    &--pb-md-20 {
      padding-bottom: 20px;
    }
    &__body {
      &--pt-md-20 {
        padding-top: 20px;
      }
    }
  }

}