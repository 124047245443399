#google_translate_element {
  span {
    display: none;
  }
  .goog-te-combo {
    position: relative;
    display: block;
    top: -4px;
    height: 43px;
    width: 100%;
    border-radius: 6px;
    background-color: $white;
    color: #54585A;
    border: none !important;
    font-size: 18px;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    @include mb;
    line-height: 1.5;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border-radius: 0.25rem;
    appearance: none;
  }
  option {
    font-weight: 400;
  }
}