.s-product {

  &__title {
    margin-bottom: 20px;
    @include sr;
    font-size: 50px;
    line-height: 81px;
    color: $abbey;
  }

  &__image {
    margin-bottom: 30px;
    border-radius: 6px;
    width: 100%;
  }

  &__content {
    margin-bottom: 30px;
    @include mr;
    font-size: 20px;
    line-height: 29px;
    color: $abbey;

    @include headers;

    p { margin-bottom: 35px; }

  }

  &__data {
    margin-bottom: 35px;
  }

  &__price {
    @include mb;
    font-size: 24px;
    line-height: 29px;
    text-decoration: none;
    color: $hot-cinnamon;

    &:hover {
      color: $hot-cinnamon;
    }
  }


  &__limited {
    display:  inline-block;
    margin-left: 15px;
    @include mr;
    font-size: 24px;
    line-height: 29px;
    color: $milano-red;

    &--ml-0 {
      margin-left: 0;
    }
    &--mb-10 {
      margin-bottom: 20px;
    }

  }

  &__fix {
    @include mb;
    font-size: 24px;
    line-height: 29px;
    text-decoration: none;
    color: $hot-cinnamon;

    &--small {
      font-size: 18px;
    }

    &:hover {
      color: $hot-cinnamon;
    }
  }

  &__review {
    @include mr;
    font-size: 20px;
    line-height: 29px;
    text-decoration: none;
    color: $teal;

    &:hover {
      color: $teal;
    }
  }

  &__stars {
    margin-left: 20px;
  }

  &__addtocart {
    &--disabled {
      opacity: .25;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    &__title {
      font-size: 35px;
      line-height: 42px;
    }
    &__image {
      margin-bottom: 12px;
    }
    &__list-row {
      margin-bottom: 40px;
      --bs-gutter-x: 12px;
    }
    &__data {
      margin-bottom: 20px;
    }
    &__review {
      font-size: 12px;
      line-height: 15px;
    }
    &__price {
      font-size: 18px;
      line-height: 22px;
    }
    &__fix {
      font-size: 18px;
      line-height: 22px;
    }
    &__content {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 22px;
      p { margin-bottom: 22px; }
    }
    &__limited {
      font-size: 18px;
      line-height: 22px;
    }
  }

}