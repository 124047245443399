.s-cancel {
  &__content {
    @include mr;
    font-size: 20px;
    line-height: 35px;
    color: $abbey;
  }

  // Mobile

  @media(max-width: $md - 1){
    &__content {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 18px;
    }
  }

}