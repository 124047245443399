.c-donatedetail {
  margin-top: 15px;
  @include ml;
  font-size: 20px;
  line-height: 24px;
  color: $abbey;

  &__meta {
    margin-bottom: 25px;
  }

  &__seperator {
    margin-left: 90px;
    margin-right: 90px;
  }

  &__reason {
    margin-bottom: 25px;
  }

  &__additional {
    margin-bottom: 25px;
  }

  // Responsive

  @media(max-width: $xl - 1){
    &__seperator {
      margin-left: 45px;
      margin-right: 45px;
    }
  }

  @media(max-width: $lg - 1){
    &__seperator {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  @media(max-width: $md - 1){
    font-size: 14px;
    line-height: 22px;

    &__meta {
      margin-bottom: 10px;
    }

    &__reason {
      margin-bottom: 10px;
    }
  
    &__additional {
      margin-bottom: 10px;
    }

  }

}