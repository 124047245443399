@media print {

  .at-expanding-share-button-toggle-bg {
    display: none !important;
  }

  .g-recaptcha {
    display: none !important;
  }

  .s-totop {
    display: none !important;
  }

  .s-body {

    .s-menu {
      display: none;
    }

    .s-headbar {
      -webkit-print-color-adjust: exact !important;
    }

    .s-headbar__language {
        display: none;
    }

    .s-headbar__currency {
      display: none;
    }

    .s-headbar__offset {
      display: none !important;
    }

    .s-headbar__join {
      display: none !important;
    }

    .s-headbar__donate {
      display: none !important;
    }

    .s-headlogo__tools {
      display: none;
    }

    .s-crumb {
      border-top: 6px solid $teal;
      border-bottom: 2px solid $abbey;
    }

    .c-submenu {
      display: none;
    }

    .s-content {
      background-image: url("/static/img/curve_lower_1.png");
      background-position: top;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }

    .s-content__aside {
      display: none !important;
    }

    .s-content__main {
      width: 100% !important;
      padding-left: 0px !important;
    }

    .c-accordion {
      &__fold {
        .c-accordion__body{
          display: block;
        }
        .c-accordion__action {
          &:before {
            content: none;
          }
        }
      }
    }

    .s-content__download {
      padding-left: 0;
      padding-right: 0;
      width: auto;
      background-color: $white;
      background-image: none;
      color: $abbey;
    }

    .s-footer {
      display: none !important;
    }

    .s-fmenu {
      display: none !important;
    }

    .s-registered {
      border-top: 2px solid $abbey;
      padding-top: 20px;
    }
  }

}
