.c-warning {

  $self: &;

  margin-bottom: 20px;
  @include mr;
  font-size: 20px;
  line-height: 29px;
  color: $guardsman-red;

  &__unavailable {
    display: none;
  }

  &--unavailable {
    margin-bottom: 25px;
    #{ $self }__unavailable {
      display: block;
    }
  }

  @media(max-width: $md - 1){
    margin-bottom: 0px;
  }

}
