.c-pagination {
  text-align: center;

  &__block {
    display: inline-block;
    border-radius: 6px;
    height: 46px;
    width: 46px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: $hot-cinnamon;
    font-size: 18px;
    line-height: 46px;
    text-align: center;
    color: $white;
    text-decoration: none;
    &:hover {
      color: $white;
    }

    &--active {
      background-color: $teal;
    }

  }

  &--mb-40 {
    margin-bottom: 40px;
  }

}