.c-scale {
  padding-bottom: 40px;

  &__gauge {
    width: 100%;
    height: 23px;
    background-color: $neptune;
  }

  &__bar {
    width: 0%;
    height: 23px;
    background-color: $teal;
  }

  &__beg {
    @include mb;
    font-size: 20px;
    line-height: 35px;
    color: $teal;
  }

  &__target {
    @include mb;
    font-size: 20px;
    line-height: 35px;
    color: $teal;
  }

  &__mark {
    @include mb;
    margin-left: 0;
    font-size: 20px;
    line-height: 35px;
    color: $teal;
  }

  &__align {
    display: inline-block;
    transform: translateX(-50%);

    &--left {
      transform: translateX(0%);
    }

    &--right {
      transform: translateX(-100%);
    }
  }

  // Mobile

  @media(max-width: $md - 1){

    &__gauge {
      height: 12px;
    }

    &__bar {
      height: 12px;
    }

    &__beg {
      margin-top: 8px;
      font-size: 14px;
      line-height: 14px;
    }
  
    &__target {
      margin-top: 8px;
      font-size: 14px;
      line-height: 14px;
    }

    &__mark {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 14px;
    }
  }

}