.s-sales {
  margin-bottom: 50px;

  th {
    border-bottom: 1px solid #000;
  }

  @media(max-width: $md - 1){
    margin-bottom: 20px;
  }

}