.c-submenu {

  $self: &;

  position: relative;
  display: none;
  width: 100%;

  &__toggle {
    position: relative;
    display: block;
    border-radius: 6px;
    border: 1px solid $abbey;
    background-color: $white;
    width: 100%;
    height: 32px;
    padding-left: 10px;
    padding-right: 32px;
    @include mr;
    font-size: 12px;
    line-height: 32px;
    text-decoration: none;
    color: $abbey;
    &:hover {
      color: $abbey;
    }
  }

  &__arrow {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 13px;
    height: 10px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('/static/img/breadcrumb.png');
    transform: rotate(90deg);

    &--down {
      transform: rotate(270deg);
    }

  }

  &__lvl0-wrapper {
    display: none;
    &--open {
      display: block;
    }
  }

  &__lvl1-wrapper {
    &--open {
      #{ $self }__lvl1-arrow {
        transform: rotate(180deg);
      }
      #{ $self }__lvl2-list {
        display: block;
      }
    }
  }

  &__lvl1-list {
    position: absolute;
    z-index: 90;
    top: 31px;
    left: 0;
    right: 0;
    margin-bottom: 0;
    border-radius: 6px;
    border: 1px solid $abbey;
    overflow: hidden;
    background-color: $white;
    @include mb;
    font-size: 12px;
    line-height: 15px;
  }

  &__lvl1-item {
    position: relative;
    padding: 12px 20px;

    &:last-child:after {
      content: none;
    }

  }

  &__lvl1-title {
    text-decoration: none;
  }

  &__lvl1-arrow {
    position: absolute;
    right: 20px;
    text-decoration: none;
  }

  &__lvl2-wrapper {
    &--open {
      #{ $self }__lvl2-arrow {
        transform: rotate(180deg);
      }
      #{ $self }__lvl3-list {
        display: block;
      }
    }
  }

  &__lvl2-list {
    display: none;
    margin-bottom: 0;
  }

  &__lvl2-item {
    position: relative;
    padding: 12px 20px;
    font-size: 12px;
    line-height: 15px;

    &:last-child:after {
      content: none;
    }
  }

  &__lvl2-title {
    text-decoration: none;
  }

  &__lvl2-arrow {
    position: absolute;
    right: 20px;
    text-decoration: none;
  }

  &__lvl3-wrapper {
    &--open {
      #{ $self }__lvl3-arrow {
        transform: rotate(180deg);
      }
      #{ $self }__lvl4-list {
        display: block;
      }
    }
  }

  &__lvl3-list {
    display: none;
    margin-bottom: 0;
  }

  &__lvl3-item {
    position: relative;
    background-color: $white;
    padding: 12px 20px;
    font-size: 12px;
    line-height: 15px;

    &:last-child:after {
      content: none;
    }

  }

  &__lvl3-title {
    text-decoration: none;
  }

  &__lvl3-arrow {
    position: absolute;
    right: 20px;
    text-decoration: none;
  }

  &__lvl4-list {
    display: none;
    margin-bottom: 0;
  }

  &__lvl4-item {
    position: relative;
    padding: 12px 20px;
    font-size: 12px;
    line-height: 15px;

    &:last-child:after {
      content: none;
    }

  }

  &__lvl4-title {
    text-decoration: none;
  }

  &__lvl4-arrow {
    position: absolute;
    right: 20px;
    text-decoration: none;
    &:hover {
    }
  }

  // Colours

  &__lvl1-item {
    background-color: $white;
  }
  &__lvl2-item {
    background-color: $teal;
  }
  &__lvl3-item {
    background-color: $jungle-mist;
  }
  &__lvl4-item {
    background-color: $aqua-haze;
  }

  &__lvl1-title, &__lvl1-arrow {
    color: $abbey;
    &:hover {
      color: $abbey;
    }
  }
  &__lvl2-title, &__lvl2-arrow {
    color: $white;
    &:hover {
      color: $white;
    }
  }
  &__lvl3-title, &__lvl3-arrow {
    color: $abbey;
    &:hover {
      color: $abbey;
    }
  }
  &__lvl4-title, &__lvl4-arrow {
    color: $abbey;
    &:hover {
      color: $abbey;
    }
  }

  &__lvl1-wrapper {
    &--open {
      >#{$self}__lvl1-item {
        background-color: $elephant;
        #{$self}__lvl1-title, #{$self}__lvl1-arrow {
          color: $white;
          &:hover {
            color: $white;
          }
        }
      }
    }
  }

  &__lvl2-wrapper {
    &--open {
      >#{$self}__lvl2-item {
        background-color: $elephant;
        #{$self}__lvl2-title, #{$self}__lvl2-arrow {
          color: $white;
          &:hover {
            color: $white;
          }
        }
      }
    }
  }

  &__lvl3-wrapper {
    &--open {
      >#{$self}__lvl3-item {
        background-color: $elephant;
        #{$self}__lvl3-title, #{$self}__lvl3-arrow {
          color: $white;
          &:hover {
            color: $white;
          }
        }
      }
    }
  }

  /*
    &:after {
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 20px;
      border-bottom: 1px solid rgba($teal, 45%);
      content: '';
    }
  */


  // Mobile

  @media(max-width: $md - 1){
    display: block;
  }

}
