.c-burger {
  display: none;
  margin-left: 15px;
  text-decoration: none;

  &__line {
    display: block;
    margin-bottom: 6px;
    background-color: $hot-cinnamon;
    width: 32px;
    height: 3px;
    border-radius: 1.5px;
  }
  &__title {
    @include mr;
    font-size: 11px;
    line-height: 11px;
    color: $hot-cinnamon;
  }

  @media(max-width: $md - 1){
    display: block;
  }

}