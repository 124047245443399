ul.errorlist {
  display: inline;
  margin: 0;
  padding: 0;
  li {
    display: inline;
    margin: 0;
    padding: 0;
    color: #842029;
  }
}
