.c-blkevent {
  margin-bottom: 32px;
  display: block;
  text-decoration: none;

  &__title {
    @include mb;
    font-size: 22px;
    line-height: 38px;
    color: $hot-cinnamon;
  }

  &__lead {
    @include mr;
    font-size: 22px;
    line-height: 38px;
    color: $abbey;
  }

  // Mobile

  @media(max-width: $md - 1){

    &--md-hide {
      display: none;
    }

    margin-bottom: 15px;
    &__title {
      font-size: 14px;
      line-height: 22px;
    }
    &__lead {
      font-size: 14px;
      line-height: 22px;
    }
  }

}