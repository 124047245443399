.c-center {
  display: block;
  margin-bottom: 30px;
  text-decoration: none;

  &__image {
    margin-bottom: 25px;
    border-radius: 6px;
    width: 100%;
  }

  &__title {
    margin-bottom: 28px;
    @include mb;
    font-size: 20px;
    line-height: 28px;
    color: $abbey;
  }

  &__label {
    @include mb;
    font-size: 20px;
    line-height: 28px;
    color: $abbey;
  }

  &__content {
    margin-bottom: 28px;
    @include mr;
    font-size: 20px;
    line-height: 28px;
    color: $abbey;

    p {
      margin-bottom: 28px;
      &:last-child {
        margin-bottom: 0;
      }
    }

  }

  // Mobile

  @media(max-width: $md - 1){
    margin-bottom: 20px;
    &__image {
      margin-bottom: 10px;
    }
    &__title {
      margin-bottom: 22px;
      font-size: 14px;
      line-height: 22px;
    }
    &__label {
      font-size: 14px;
      line-height: 22px;
    }
    &__content {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 22px;
      color: $abbey;
      p {
        margin-bottom: 22px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}