.c-reply {
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  border-radius: 6px;
  background-color: rgba($abbey, 15%);
  padding: 25px 20px;

  &__title {
    margin-bottom: 15px;
    @include mb;
    font-size: 20px;
    line-height: 35px;
    color: $abbey;
  }

  // Mobile

  @media(max-width: $md - 1){
    margin-bottom: 20px;
    padding: 20px 15px 0px;
  }

}