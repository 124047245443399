.s-footer {
  background-color: $wild-sand;
  padding-top: 50px;
  padding-bottom: 100px;

  &__title {
    margin-bottom: 40px;
    @include sr;
    font-size: 35px;
    line-height: 57px;
    color: $abbey;
  }

  &__list {
    margin-bottom: 0;
    li { margin-bottom: 0; }
  }

  &__buttons {
    margin-bottom: 0;
    li { margin-bottom: 10px; }
  }

  &__link {
    @include mr;
    font-size: 20px;
    line-height: 40px;
    text-decoration: none;
    color: $abbey;

    &:hover {
      color: $teal;
    }
  }

  &__button {
    margin-bottom: 10px;
  }

  &__socials {
    margin-top: 6px;
    margin-bottom: 20px;
  }

  &__button {
    margin-bottom: 10px;
  }

  // Responsive

  @media(max-width: $xl - 1){
    &__socials {
      display: none;
    }
  }

  @media(max-width: $lg - 1){
    padding-bottom: 50px;
    &__section {
      margin-bottom: 20px;
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    padding-top: 30px;
    padding-bottom: 5px;

    &__title {
      margin-bottom: 10px;
      font-size: 22px;
      line-height: 36px;
    }

    &__link {
      font-size: 14px;
      line-height: 22px;
    }

    &__group {
      margin-bottom: 0;
    }

    &__button {
      &--split {
        width: calc(50% - 5px) !important;
      }
    }

  }

}