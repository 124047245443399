.s-content {
  background-position: top right;
  background-repeat: no-repeat;
  background-image: url('/static/img/curve_lower_2.png');

  background-size: calc(50vw + 416px) auto;
  @media(max-width: $xxl - 1){
    background-size: calc(49.7487437186vw + 329.0150753769px) auto;
  }
  @media(max-width: $xl - 1){
    background-size: calc(49.7584541063vw + 238.3961352657px) auto;
  }
  @media(max-width: $lg - 1){
    background-size: calc(49.7757847534vw + 117.7219730942px) auto;
  }

  /* Calc Generator

  @include poly-fluid-sizing('top', (
    2800px: 1816px,
    1400px: 1116px,
    1399px: 1025px,
    1200px: 926px,
    1199px: 835px,
    992px: 732px,
    991px: 611px,
    768px: 500px
  ));

  */

  &__aside {
    width: 232px;
  }

  &__main {
    padding-top: 50px;
    padding-left: 60px;
    padding-bottom: 40px;
    width: calc(100% - 232px);

    &--pb-0 {
      padding-bottom: 0px;
    }

    &--pb-10 {
      padding-bottom: 10px;
    }

    &--pb-100 {
      padding-bottom: 100px;
    }

  }

  &__title {
    margin-bottom: 40px;
    @include sr;
    font-size: 50px;
    line-height: 81px;
    color: $abbey;

    &--mb-10 {
      margin-bottom: 10px;
    }

    &--teal {
      color: $teal;
    }

    &--hot-cinnamon {
      color: $hot-cinnamon;
    }

  }

  &__subtitle {
    margin-bottom: 30px;
    @include sr;
    font-size: 35px;
    line-height: 45px;
    color: $abbey;

    &--mb-20 {
      margin-bottom: 20px;
    }

    &--mb-40 {
      margin-bottom: 40px;
    }

  }

  &__subtitle-2 {
    margin-bottom: 20px;
    @include mb;
    font-size: 20px;
    line-height: 35px;
    color: $teal;
  }

  &__subtitle-3 {
    margin-top: 40px;
    margin-bottom: 40px;
    @include sr;
    font-size: 35px;
    line-height: 45px;
    color: $hot-cinnamon;
  }

  &__video {
    margin-bottom: 50px;

    &--100p {
      width: 100%;
    }
    &--75p {
      width: 75%;
    }
    &--50p {
      width: 50%;
    }
    &--25p {
      width: 25%;
    }
    &--left {
      margin-right: auto;
    }
    &--center {
      margin-right: auto;
      margin-left: auto;
    }
    &--right {
      margin-left: auto;
    }
  }

  &__content {
    margin-bottom: 50px;
    @include mr;
    font-size: 20px;
    line-height: 35px;
    color: $abbey;

    @include headers;

    &--narrow {
      line-height: 24px;
    }

    &--mb-20 {
      margin-bottom: 20px;
    }

    &--mb-40 {
      margin-bottom: 40px;
    }

    &--teal {
      color: $teal;
    }

    &--lp-mb-0 {
      &:last-child {
        margin-bottom: 0;
      }
    }

    span.teal {
      color: $teal;
    }

    a:not(.c-button) {
      text-decoration: underline;
      color: $teal;
      &:hover {
        color: $teal;
      }
    }

    h2 {
      margin: 0 0 40px;
      @include sr;
      color: $hot-cinnamon;
    }

    blockquote {
      margin-bottom: 40px;
      @include mb;
      font-size: 20px;
      line-height: 40px;
      color: $abbey;

      &:before {
        font-size: 180%;
        content: '“';
      }
      &:after {
        font-size: 180%;
        content: '”';
      }
      &.teal {
        color: $teal;
      }
      &.hot-cinnamon {
        color: $hot-cinnamon;
      }
    }

    hr {
      margin: 0 0 40px;
      padding: 0;
      background-color: $abbey;
      height: 2px;
      opacity: 1;

      &.teal {
        background-color: $teal;
      }
      &.hot-cinnamon {
      background-color: $hot-cinnamon;
      }

      &.left {
        width: 40%;
      }

      &.right {
        margin-left: 60%;
        width: 40%;
      }
    }

    p {
      margin-bottom: 35px;
      &:last-child {
        margin-bottom: 0px;
      }
    }

    ul {
      list-style: none;

      li {
        position: relative;
        &:before {
          position: absolute;
          top: -2px;
          left: 10px;
          font-size: 40px;
          content: "\2022";
          color: $teal;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }

    }

    &--teal {
      color: $teal;
    }

    &--mb-0 {
      margin-bottom: 0;
    }
    &--mb-15 {
      margin-bottom: 15px;
    }
    &--mb-75 {
      margin-bottom: 75px;
    }
  }

  &__image-full {
    margin-bottom: 40px;
    width: 100%;

    &--corner-6 {
      border-radius: 6px;
    }

    &--mb-0 {
      margin-bottom: 0;
    }

  }

  &__image-fours {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
  }

  &__image-four {
    margin-bottom: 30px;
    width: calc(50% - 15px);
  }

  &__ruler {
    margin: 0 0 20px;
    padding: 0;
    height: 1px;
    background-color: $abbey;
  }

  &__icontext {
    margin-bottom: 40px;
    @include mr;
    font-size: 20px;
    line-height: 24px;
    color: $abbey;

    img { margin-right: 24px; }

  }

  &__lead {
    margin-bottom: 25px;
    @include mr;
    font-size: 30px;
    line-height: 40px;
    color: $abbey;

    &--mb-35 {
      margin-bottom: 35px;
    }

  }

  &__prompt {
    margin-bottom: 20px;
    @include mr;
    font-size: 20px;
    line-height: 35px;
    color: $abbey;
  }

  &__quote {
    &--mb-40 {
      margin-bottom: 40px;
    }
  }

  &__quote-image {
    border-radius: 50%;
    width: 100%;

    &--square {
      border-radius: 6px;
    }

  }

  &__quote-text {
    @include mb;
    font-size: 20px;
    line-height: 40px;
    color: $teal;

    &:before {
      font-size: 180%;
      content: '“';
    }
    &:after {
      font-size: 180%;
      content: '”';
    }
  }

  &__quote-seperator {
    margin: 40px 0;
    background-color: $teal;
    width: 100%;
    height: 2px !important;
    padding: 0;
    opacity: 1;
  }

  &__block {
    display: inline-block;
    margin-bottom: 40px;
    border-radius: 3px;
    background-color: rgba($abbey, 15%);
    padding: 15px;
  }

  &__highlight {
    margin-bottom: 30px;
    @include mb;
    font-size: 30px;
    line-height: 45px;

    a {
      text-decoration: none;
      color: $hot-cinnamon;
      &:hover {
        color: $hot-cinnamon;
      }
    }

  }

  // Responsive

  @media(max-width: $xl - 1){
    &__quote-image {
      margin-bottom: 40px;
      max-width: 320px;
    }
  }

  // Mobile

  @media(max-width: $md - 1){

    &__video {
      margin-bottom: 20px;
      &-md--100p {
        width: 100%;
      }
      &-md--75p {
        width: 75%;
      }
      &-md--50p {
        width: 50%;
      }
      &-md--25p {
        width: 25%;
      }
      &-md--left {
        margin-right: auto;
      }
      &-md--center {
        margin-right: auto;
        margin-left: auto;
      }
      &-md--right {
        margin-left: auto;
      }
    }

    &--md-pb-20 {
      padding-bottom: 20px;
    }

    &--md-pb-30 {
      padding-bottom: 30px;
    }

    &__aside {
      display: none;
    }
    &__main {
      width: 100%;
      padding-top: 20px;
      padding-bottom: 0px;
      padding-left: 0;
    }
    &__title {
      margin-bottom: 20px;
      font-size: 35px;
      line-height: 42px;
    }
    &__content {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 22px;
    }
    &__subtitle {
      margin-bottom: 20px;
      font-size: 22px;
      line-height: 30px;
    }

    &__subtitle-3 {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 22px;
      line-height: 30px;
    }

    &__image-fours {
      margin-bottom: 20px;
    }

    &__image-four {
      margin-bottom: 12px;
      width: calc(50% - 6px);
    }

    &__icontext {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 18px;
      color: $abbey;
      img {
        margin-right: 5px;
        width: 14px;
      }
    }

    &__prompt {
      font-size: 14px;
      line-height: 22px;
    }

    &__check-md-mb {
      margin-bottom: 8px;
    }

    &__quote-image {
      margin-bottom: 20px;
    }

    &__image-full {
      margin-bottom: 20px;

      &--md-mb-0 {
        margin-bottom: 0;
      }

    }

    &__block {
      &--md-width-full {
        width: 100%;
      }
      &--md-mb-10 {
        margin-bottom: 10px;
      }
    }

    &__quote-seperator {
      margin: 20px 0;
    }

    &__lead {
      font-size: 18px;
      line-height: 22px;
      &--md-mb-20 {
        margin-bottom: 20px;
      }
    }

    hr {
      &.left {
        margin-bottom: 20px;
        //width: 100%;
      }

      &.right {
        margin-bottom: 20px;
        //margin-left: 0;
        //width: 100%;
      }
    }

    &__quote-text {
      font-size: 14px;
      line-height: 22px;
    }

    blockquote {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 22px;
    }

    &__quote {
      &--md-mb-20 {
        margin-bottom: 20px;
      }
    }

    &__highlight {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 22px;
    }
  }

}
