.c-number {

  &__tray {
    height: 48px;
  }

  &__btn {
    position: relative;
    display: block;
    background-color: $teal;
    height: 100%;
    width: 36px;
    color: $white;

    &:before {
      position: absolute;
      content: '';
      left: 10px;
      right: 10px;
      top: 23px;
      border-bottom: 2px solid $white;
    }

    &--less {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &--more {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      &:after {
        position: absolute;
        content: '';
        top: 16px;
        bottom: 16px;
        left: 17px;
        border-right: 2px solid $white;
      }
    }
  }

  &__input {
    background-color: $aqua-haze;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    height: 100%;
    width: 60px;
    @include mr;
    font-size: 21px;
    line-height: 48px;
    text-align: center;
    color: $abbey;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    &__tray {
      height: 37px;
    }

    &__btn {
      &:before {
        top: 17px;
      }

      &--more {
        &:after {
          top: 10px;
          bottom: 10px;
        }
      }
    }

    &__input {
      width: 45px;
      font-size: 17px;
      line-height: 37px;
    }
  }
}
