.c-form {

  &__center {
    margin-top: 20px;
  }

  &__group {
    margin-bottom: 20px;

    &--relative {
      position: relative;
    }
    &--mb-30 {
      margin-bottom: 30px;
    }
    &--mb-40 {
      margin-bottom: 40px;
    }
    &--mb-50 {
      margin-bottom: 50px;
    }
    &--mb-65 {
      margin-bottom: 65px;
    }
    &--mb-70 {
      margin-bottom: 70px;
    }
    &--condition {
      display: none;
    }
    &--mr-20 {
      margin-right: 20px;
    }

    &--custom-check {
      input {
        position: relative;
        top: -1px;
      }
    }

    &--custom-radio {
      input {
        position: relative;
        top: 5px;
      }
    }

    label{
      font-family: "Montserrat", sans-serif;
      font-size: 20px;
      margin: 0 0 15px 0;
    }
    
    ul{
      padding: 0;
      
      li{
        list-style: none;
      }

    }

    .django-ckeditor-widget{
      width: 100%;
      min-height: 160px;

      textarea{
        height: 160px;
        width: 100%;
      }

    }

  }

  &__title {
    margin-bottom: 30px;
    @include mb;
    font-size: 20px;
    line-height: 29px;
    color: $abbey;
  }

  &__label {
    margin-bottom: 24px;
    @include mr;
    font-size: 20px;
    line-height: 24px;
    color: $abbey;
  }

  &__input {
    border: 1px solid $abbey;
    border-radius: 6px;
    height: 56px;
    padding: 0 23px;
    @include mr;
    font-size: 20px;
    line-height: 56px;
    color: $abbey;

    &:focus {
      color: $abbey;
    }

    &::placeholder {
      color: $abbey;
    }

    &[required] {
      padding-right: 50px;
      background-image: url('/static/img/required@2x.png');
      background-repeat: no-repeat;
      background-position: right 15px center;
      background-size: 15px 15px;
    }

    &--area {
      height: 160px;
      padding-top: 12px !important;
      padding-top: 0px;
      line-height: 24px;
    }

    &--area-2 {
      height: 357px;
      padding-top: 12px !important;
      padding-top: 0px;
      line-height: 24px;
    }

    &--area-3 {
      height: 213px;
      padding-top: 12px !important;
      padding-top: 0px;
      line-height: 24px;
    }

    &--area-4 {
      height: 125px;
      padding-top: 12px !important;
      padding-top: 0px;
      line-height: 24px;
    }

    &--amount {
      height: 46px;
      font-size: 18px;
      line-height: 46px;
    }

    &--width-198 {
      width: 198px
    }

    &--width-316 {
      width: 316px
    }

    &--search {
      @include sr;
      font-size:28px;
    }

    &--teal {
      border: none;
      background-color: $aqua-haze;
      color: $teal;
      &:focus {
        background-color: $aqua-haze;
        color: $teal;
      }
      &::placeholder {
        color: $teal;
      }
    }
  }

  &__check-input[type='checkbox'] {
    margin-top: 0px;
    margin-right: 12px;
    width: 26px;
    height: 26px;
    border-radius: 2px;
    background-color: $white;
    border-color: $abbey;
    color: $teal;
    background-image: none;

    &:checked {
      background-color: $white;
      border-color: $abbey;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2300747B' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
      &:after {
        display: none;
      }
    }

  }

  &__radio-input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-top: 0px;
    margin-right: 12px;
    border-radius: 13px;
    border: 1px solid $abbey;
    background-clip: content-box;
    width: 26px;
    height: 26px;
    background: $white;

    &[required] {
      position: relative;
      &:after {
        position: absolute;
        top: 4px;
        right: 4px;
        font-size: 15px;
        line-height: 15px;
        content: '*';
        color: $hot-cinnamon;
      }
    }

    &:checked {
      background-image: url('/static/img/radio.png');
      &:after {
        display: none;
      }
    }

  }

  &__check-label {
    @include ml;
    font-size: 20px;
    line-height: 24px;
    color: $abbey;

    strong {
      font-weight: 700;
    }

    a {
      color: $teal;
      &:hover {
        color: $teal;
      }
    }

    &--width-limit {
      width: calc( 100% - 45px);
    }

    &--size-16 {
      font-size: 16px;
      line-height: 19px;
    }

    &--lh-35 {
      line-height: 35px;
    }

    &--mr-60 {
      margin-right: 60px;
    }
  }

  &__radio-label {
    @include ml;
    font-size: 20px;
    line-height: 24px;
    color: $abbey;

    &--width-limit {
      width: calc( 100% - 45px);
    }

    &--width-limit-110 {
      width: calc( 100% - 110px);
    }

    &--size-16 {
      font-size: 16px;
      line-height: 19px;
    }

    &--lh-35 {
      line-height: 35px;
    }

    &--mr-60 {
      margin-right: 60px;
    }
  }

  &__igroup {
    position: relative;
    top: 0 !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border: 1px solid $abbey !important;
    padding-left: 15px !important;
    padding-right: 25px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background-color: transparent !important;
    height: 56px;
    @include mr;
    font-size: 20px;
    line-height: 56px !important;
    color: $abbey;
  }

  &__select {
    border: 1px solid $abbey;
    border-radius: 6px;
    height: 56px;
    @include mr;
    font-size: 20px;
    line-height: normal;
    color: $abbey;

    &[required] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url('/static/img/required@2x.png');
      background-repeat: no-repeat, no-repeat;
      background-position: right 0.75rem center, right 2.25rem center;
      background-size: 16px 12px, 15px 15px;
      padding-right: 50px;
    }

  }

  &__currency {
    border: none;
    border-radius: 6px;
    height: 43px;
    @include mb;
    font-size: 18px;
    line-height: normal;
    color: $abbey;
  }

  &__option-wn {
    font-weight: 400;
  }

  &__file-label {
    @include mr;
    font-size: 16px;
    line-height: 19px;
    color: $abbey;
  }

  &__file-input {
    @include mr;
    border-radius: 6px;
    border: 1px solid #ffffff;
    font-size: 16px;
    line-height: normal;
    color: $abbey;
  }

  &__counter {
    @include mr;
    font-size:18px;
    line-height: 22px;
    color: $abbey;

    &--nobr {
      white-space: nowrap;
    }

  }

  &__seperator {
    margin: 0 0 40px;
    padding: 0;
    height: 12px;
    background-color: $abbey;
    opacity: 1;
  }

  &__stripe-field iframe {
    position: relative !important;
    top: -6px;
  }

  &__help {
    @include mr;
    margin-top: 3px;
    font-size: 12px;
    line-height: 14px;
    opacity: .75;
    color: $abbey;
  }

  // Responsive

  @media(max-width: $lg - 1){
    &__group {
      &--lg-mb-5 {
        margin-bottom: 5px;
      }
      &--lg-mb-10 {
        margin-bottom: 10px;
      }
      &--lg-mb-20 {
        margin-bottom: 20px;
      }
      &--wrap-none {
        white-space: nowrap;
      }
      &--wrap-normal {
        white-space: normal;
      }
    }
    &__check-label {
      &--mr-60 {
        margin-right: 15px;
      }
    }
    &__radio-label {
      &--mr-60 {
        margin-right: 15px;
      }
    }
    &__counter {
      font-size: 12px;
      line-height: 15px;
    }
  }

  .processing_message{
    display: none;
  }

  // Mobile

  @media(max-width: $md - 1){

    &__igroup {
      height: 45px;
      font-size: 14px;
      line-height: 45px !important;
    }

    &__label {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 18px;
    }

    &__input {
      border-radius: 3px;
      height: 45px;
      padding-left: 18px;
      padding-right: 18px;
      font-size: 14px;
      line-height: 45px;

      &--area {
        height: 128px;
        padding-top: 10px !important;
        padding-top: 0px;
        line-height: 18px;
      }

      &--area-2 {
        height: 308px;
        padding-top: 12px !important;
        padding-top: 0px;
        line-height: 24px;
      }

      &--area-3 {
        height: 213px;
        padding-top: 12px !important;
        padding-top: 0px;
        line-height: 24px;
      }

      &--area-4 {
        height: 66px;
        padding-top: 6px !important;
        padding-top: 0px;
        font-size: 14px;
        line-height: 18px;
      }

    }

    &__seperator {
      margin-bottom: 20px;
    }

    &__group {
      &--md-mb-20 {
        margin-bottom: 20px;
      }
      &--md-mb-10 {
        margin-bottom: 10px;
      }
      &--width-md-full {
        width: 100%;
      }

      &--custom-check {
        input {
          position: relative;
          top: -4px;
        }
      }
  
      &--custom-radio {
        input {
          position: relative;
          top: 2px;
        }
      }

    }

    &__check-input[type='checkbox'] {
      margin-top: 4px;
      margin-right: 5px;
      width: 20px;
      height: 20px;
    }

    &__check-label {
      font-size: 13px;
      line-height: 16px;
    }

    &__select {
      border: 1px solid $abbey;
      border-radius: 3px;
      height: 45px;
      font-size: 14px;
    }

    &__radio-label {
      font-size: 13px;
      line-height: 16px;
    }

    &__radio-input[type='radio'] {
      margin-top: 3px;
      margin-right: 4px;
      border-radius: 10px;
      width: 20px;
      height: 20px;
      background: $white;

      &:checked {
        background-image: url('/static/img/radio.png');
        background-size: contain;
      }
    }


    &__check-label {
      &--md-width-min-35 {
        width: calc(100% - 35px);
      }
    }
    &__radio-label {
      &--md-width-min-35 {
        width: calc(100% - 35px);
      }
    }

  }
}

.c-form{

  label{
    color: #54585A;
  }

  .radio-custom-style{

    label{
      display: flex;
      align-items: center;
      column-gap: 15px;
      color: #54585A;
    }

    input[type="radio"]{
      appearance: none;
      -webkit-appearance: none;
      border: 1px solid #54585A;
      border-radius: 2px;
      height: 26px;
      width: 26px;
      background-color: #ffffff;

      &:checked{
        background-color: #ffffff;
        border-color: #54585A;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2300747B' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        background-size: contain;
      }

    }

  }

}
