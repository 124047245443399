.panels{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .panel{
    width: 32%;
    margin: 0 0 25px 0;

    .c-blkfind{
      height: 100%;
      display: flex;
      text-decoration: none;
      flex-direction: column;
      justify-content: center;
    }

    @media(max-width: 991px){
      width: 100%;
      margin: 0 0 15px 0;

      &:last-child{
        margin: 0;
      }

    }

  }

  @media(max-width: 991px){
    flex-direction: column;
  }

}


.c-blkfind {

  $self: &;

  display: block;
  box-shadow: 0px 3px 6px 0px rgba($black, 16%);
  border-radius: 30px;
  background-color: $hot-cinnamon;
  padding: 22px 20px 26px;
  text-decoration: none;

  &__title {
    margin-bottom: 20px;
    @include sr;
    font-size: 35px;
    line-height: 57px;
    text-align: center;
    color: $white;
  }

  &__subtitle {
    @include mb;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $white;
  }

  &__image {
    margin-bottom: 23px;
    border-radius: 6px;
    width: 100%;
  }

  &--narrow {

    #{ $self }__title {
      margin-bottom: 15px;
      font-size: 30px;
      line-height: 49px;
    }

  }

  // Mobile

  @media(max-width: $md - 1){
    margin-bottom: 16px;
    padding: 20px 18px 20px;
    &__title {
      margin-bottom: 20px;
      font-size: 28px;
      line-height: 45px;
    }
    &__subtitle {
      font-size: 18px;
      line-height: 22px;
    }

    &--narrow {

      #{ $self }__title {
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 45px;
      }
    }
  }
}