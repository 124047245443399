.s-products {
  padding-bottom: 40px;

  &__showing {
    @include mr;
    font-size: 18px;
    line-height: 22px;
    color: $abbey;
  }

  &__seperator {
    margin: 0 0 120px;
    padding: 0;
    height: 1px;
    background-color: $abbey;
  }

  &__subtitle {
    margin-bottom: 40px;
    @include sr;
    font-size: 35px;
    line-height: 45px;
    color: $abbey;
  }

  // Responsive

  @media(max-width: $lg - 1){
    &__showing {
      &--lg-size-12 {
        margin-right: 5px;
        font-size: 12px;
        line-height: 15px;
      }
    }
    &__seperator {
      margin-bottom: 30px;
    }
  }

  @media(max-width: $md - 1){
    padding-bottom: 20px;

    &__row {
      margin-bottom: 20px;
      --bs-gutter-x: 12px;
      &:last-child {
        margin-bottom: 0px;
      }
    }

    &__subtitle {
      margin-bottom: 20px;
      font-size: 22px;
      line-height: 30px;
    }

  }

}
