.s-headbar {
  position: relative;
  background-color: $hot-cinnamon;
  height: 60px;

  &__join {
    display: none !important;
    margin-right: 10px;
  }

  &__donate {
    display: none !important;
    margin-right: 10px;
  }

  &__topright-outer, &__topright-inner {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  &__topright-inner {
    display: none;
  }

  &__wrapper {
    position: relative;
  }

  &__language {
    border-radius: 6px;
    background-color: $white;
    width: 220px;
    height: 43px;
    overflow: hidden;
  }

  &__currency {
    margin-left: 20px;
    border-radius: 6px;
    background-color: $white;
    width: 125px;
    height: 43px;
    overflow: hidden;
  }

  &__login {
    display: block;
    margin-left: 20px;
    border-radius: 6px;
    background-color: $white;
    width: 154px;
    height: 43px;
    @include mb;
    font-size: 18px;
    line-height: 43px;
    text-align: center;
    text-decoration: none;
    color: $hot-cinnamon;

    &:hover {
      color: $hot-cinnamon;
    }

  }



  &__social {
    margin-left: 12px;
  }

  // Responsive

  @media(max-width: 1539px) and (min-width: 768px){
    &__topright-outer {
      display: none;
    }
    &__topright-inner {
      display: block;
    }
  }

  @media(max-width: 1539px){
    &__offset {
      margin-right: 125px;
    }
  }

  @media(max-width: $xxl - 1){
    &__socials {
      display: none;
    }
  }

  @media(max-width: $lg - 1){
    &__login {
      margin-left: 10px;
      width: 110px;
    }
  }

  // Mobile
  @media(max-width: $md - 1){
    height: 52px;
    background-color: $white;

    &__language {
      display: none;
    }
    &__currency {
      display: none;
    }
    &__login {
      display: none;
    }
    &__topright-outer {
      width: 52px;
    }

    &__join {
      display: block !important;
    }

    &__donate {
      display: block !important;
    }

  }

}