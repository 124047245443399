.s-keep {
  background-color: $teal;
  padding-top: 10px;
  padding-bottom: 10px;

  &__first-name {
    width: 17.5221%;
    input {
      width: 100%;
    }
  }

  &__last-name {
    width: 17.5221%;
    input {
      width: 100%;
    }
  }

  &__email-address {
    width: 27.9646%;
    input {
      width: 100%;
    }
  }

  &__button {
    width: 31.6814%;
    input {
      width: 100%;
    }
  }

  // Responsive

  @media(max-width: $xl - 1){
    &__first-name {
      width: calc(50% - 10px);
      input {
        width: 100%;
      }
    }
    &__last-name {
      width: calc(50% - 10px);
      input {
        width: 100%;
      }
    }
    &__email-address {
      width: 100%;
      input {
        width: 100%;
      }
    }
    &__button {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }

}
