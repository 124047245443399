.c-dialog {

  margin-bottom: 40px;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  border-radius: 6px;
  background-color: $white;
  padding: 20px 15px;

  &__name {
    margin-bottom: 12px;
    @include mb;
    font-size: 20px;
    line-height: 35px;
    color: $abbey;
  }

  &__date {
    @include mr;
    font-size: 16px;
    line-height: 16px;
    color: $abbey;

    i {
      color: $teal;
    }

  }

  &__content {
    margin-bottom: 12px;
    @include mr;
    font-size: 20px;
    line-height: 35px;
    color: $abbey;
  }

  &__reply {
    @include mr;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none;
    color: $abbey;

    &:hover {
      color: $abbey;
    }

    i {
      color: $hot-cinnamon;
    }

  }

  &--reply {
    border: 2px solid $teal;
  }

  // Mobile

  @media(max-width: $md - 1){
    padding: 10px 15px;

    &__name {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 16px;
    }

    &__date {
      font-size: 12px;
      line-height: 16px;
    }

    &__content {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 22px;
    }

    &__reply {
      font-size: 12px;
      line-height: 16px;
    }
  }

}