.c-product {
  display: block;
  margin-bottom: 40px;
  text-decoration: none;
  color: $abbey;

  &:hover {
    color: $abbey;
  }

  &__image-wrap {
    position: relative;
  }

  &__image {
    margin-bottom: 12px;
    border-radius: 6px;
    width: 100%;
  }

  &__limited {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50%;
  }

  &__title {
    margin-bottom: 25px;
    display: block;
    min-height: 40px;
    @include mr;
    font-size: 18px;
    line-height: 20px;
  }

  &__price {
    @include mb;
    font-size: 18px;
    line-height: 29px;
    color: $hot-cinnamon;
  }

  &__more {
    @include mr;
    font-size: 18px;
    line-height: 29px;
    color: $teal;
  }

  &__out {
    @include mr;
    font-size: 18px;
    line-height: 29px;
    color: $milano-red;
  }

  // Mobile

  @media(max-width: $md - 1){
    margin-bottom: 12px;

    &__title {
      margin-bottom: 10px;
      min-height: 30px;
      font-size: 12px;
      line-height: 15px;
    }
    &__price {
      font-size: 12px;
      line-height: 15px;
    }
    &__more {
      font-size: 12px;
      line-height: 15px;
    }

    &__out {
      font-size: 12px;
      line-height: 15px;
    }

  }

}