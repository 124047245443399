.c-modal {

  &__dialog {
    @media(min-width: 992px){
      max-width: 843px;
    }
  }

  &__header {
    position: relative;
    height: 100px;
    border-bottom: none;
    padding-left: 40px;
    padding-right: 40px;

    &--taller {
      height: 135px;
    }

  }

  &__title {
    @include mb;
    font-size: 24px;
    line-height: 35px;
    color: $abbey;

    &--sr-35 {
      @include sr;
      font-size: 35px;
    }

  }

  .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  &__body {
    padding-left: 40px;
    padding-right: 40px;

    &--pt-0 {
      padding-top: 0;
    }

  }

  &__footer {
    border-top: none;
    padding-left: 40px;
    padding-right: 40px;

    &--center {
      justify-content: space-around;
    }
  }

  &__content {
    @include mr;
    font-size: 20px;
    line-height: 35px;
    color: $abbey;

    @include headers;

    &--mb-40 {
      margin-bottom: 40px;
    }

  }

  // Mobile

  @media(max-width: $md - 1){
    &__header {
      padding: 40px 15px 25px;
      height: auto;
      min-height: 80px;

      &--no-indent {
        padding-left: 15px;
        padding-right: 15px;
      }

    }
    &__title {
      font-size: 14px;
      line-height: 18px;

      &--md-size-22 {
        font-size: 22px;
        line-height: 36px;
      }
    }
    &__body {
      padding: 0px 15px 0px;
    }
    &__footer {
      padding: 0px 15px 0px;
    }

    &__content {
      font-size: 14px;
      line-height: 18px;

      &--mb-40 {
        margin-bottom: 20px;
      }

    }

  }
}