.c-event {

  $self: &;

  display: flex;
  flex-direction: column;
  height: 100%;

  padding-bottom: 40px;
  @include mr;
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;
  color: $abbey;

  &:hover {
    color: $abbey;
  }

  &__thumb-wrap {
    position: relative;
  }

  &__thumb {
    margin-bottom: 12px;
    border-radius: 6px;
    width: 100%;
  }

  &__thumb-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 60%;
  }

  &__title {
    min-height: 87px;
    margin-bottom: 10px;
    @include mb;
    font-size: 18px;
    line-height: 29px;
    color: $abbey;
  }

  &__meta {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__col-1 {
    padding-bottom: 18px;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  &__col-2 {
    padding-bottom: 18px;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__icon {
    margin-right: 15px;
    color: $teal;
  }

  &__info {
    &--teal {
      @include mb;
      color: $teal;
    }
  }

  &__seperator {
    width: 100%;
  }

  // Mobile

  @media(max-width: $md - 1){
    margin-bottom: 0;
    font-size: 12px;
    line-height: 15px;

    &__meta {
      display: block;
    }

    tr {
      display: block;
    }

    &__seperator {
      display: none;
    }

    &__col-1 {
      display: block;
      padding-bottom: 10px;
    }

    &__col-2 {
      display: block;
      padding-bottom: 10px;
    }

    &__icon {
      margin-right: 10px;
    }

    &__title {
      @include mb;
      font-size: 12px;
      line-height: 15px;
      color: $abbey;
    }

  }

}