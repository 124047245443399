.s-sitemap {

  ul {
    list-style: none;

    li {
      position: relative;

      @include mr;
      font-size: 20px;
      line-height: 35px;

      a {
        text-decoration: none;
        color: $abbey;
        &:hover {
          color: $abbey;
        }
      }

      &:before {
        position: absolute;
        top: -2px;
        left: 10px;
        font-size: 40px;
        content: "\2022";
        color: $teal;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }

  @media(max-width: $md - 1){
    ul {
      li {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

}