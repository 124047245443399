.s-headlogo {
  background-color: $white;
  padding-bottom: 25px;
  height: 217px;

  &__logo {
    width: 297px;
  }

  &__tools {
    padding-top: 45px;
  }

  &__buttons {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &__button {
    margin-left: 15px;
  }

  &__links {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &__link {
    margin-left: 15px;
    margin-right: 15px;
    @include mb;
    font-size: 16px;
    line-height: 44px;
    text-decoration: none;
    color: $abbey;
    &:hover {
      color: $abbey;
    }
    i {
     font-size: 20px; 
    }
  }

  &__search {
    position: relative;
    height: 46px;
  }

  &__search-top {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__cart {
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: $teal;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 16px;
    line-height: 18px;
    text-decoration: none;
    color: $white;

    &:hover {
      color: $white;
    }

    &--show {
      display: inline-flex;
    }

  }

  // Responsive

  @media(max-width: $lg - 1){
    &__search {
      margin-bottom: 6px;
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    height: 100px;

    &__link {
      display: none;
    }
    &__logo {
      width: 131px;
    }
    &__tools {
      padding-top: 20px;
    }
  }

}