.c-testimonials {

  position: relative;
  margin-bottom: 65px;
  overflow: hidden;

  &__slide {
    border-radius: 25px;
    box-shadow: 0px 3px 6px 0px rgba($black, 16%);
    background-color: $aqua-haze;
    padding: 40px 50px 50px;
  }

  &__quote {
    margin-bottom: 32px;
    @include sr;
    font-size: 28px;
    line-height: 52px;
    color: $teal;

    @include headers;

  }

  &__author {
    @include mr;
    font-size: 25px;
    line-height: 28px;
    color: $abbey;
  }

  &__fade-left {
    position: absolute;
    z-index: 500;
    left: 0;
    top: 0;
    bottom: 0;
    width: 25%;
    background: rgb($white, 50%);
    background: linear-gradient(90deg, rgba($white, 100%) 0%, rgba($white, 0%) 100%);
  }

  &__fade-right {
    position: absolute;
    z-index: 500;
    right: 0;
    top: 0;
    bottom: 0;
    width: 25%;
    background: rgb($white, 50%);
    background: linear-gradient(90deg, rgba($white, 0%) 0%, rgba($white, 1000%) 100%);
  }

  &__owl-prev {
    position: absolute;
    display: block;
    z-index: 500;
    left: 0;
    top: 50%;
    margin-top: -20px;
    width: 25px;
    font-size: 40px;
    line-height: 40px;
    color: $abbey;

    &:hover {
      color: $abbey;
    }
  }

  &__owl-next {
    position: absolute;
    display: block;
    z-index: 500;
    right: 0;
    margin-top: -20px;
    top: 50%;
    width: 25px;
    font-size: 40px;
    line-height: 40px;
    color: $abbey;

    &:hover {
      color: $abbey;
    }

  }

  // Mobile

  @media(max-width: $md - 1){

    margin-bottom: 45px;

    &__inner {
      position: relative;
      width: 150%;
      margin-left: -25%;
    }

    &__fade-left {
      left: 12.5%;
      width: 12.5%;
    }

    &__fade-right {
      right: 12.5%;
      width: 12.5%;
    }

    &__slide {
      border-radius: 6px;
      padding: 14px 18px 14px;
    }
    &__quote {
      margin-bottom: 10px;
      font-size: 22px;
      line-height: 30px;
    }
    &__author {
      font-size: 18px;
      line-height: 22px;
    }

  }

}
