@mixin section($dpt, $dpb, $mpt, $mpb){
  @include poly-fluid-sizing('padding-top', ($dsk: $dpt, $mob: $mpt));
  @include poly-fluid-sizing('padding-bottom', ($dsk: $dpb, $mob: $mpb));
}

@mixin content($dfs, $dlh, $mfs, $mlh){
  @include poly-fluid-sizing('font-size', ($dsk: $dfs, $mob: $mfs));
  @include poly-fluid-sizing('line-height', ($dsk: $dlh, $mob: $mlh));

  p {
    @include poly-fluid-sizing('margin-bottom', ($dsk: $dlh, $mob: $mlh));
  }
  a {
    text-decoration: none;
    color: $glob-anchor;
    &:hover {
      text-decoration: underline;
      color: $glob-anchor;
    }
  }
  ul {
    @include poly-fluid-sizing('margin-bottom', ($dsk: $dlh, $mob: $mlh));
  }
  li {
    @include poly-fluid-sizing('margin-bottom', ($dsk: $dlh, $mob: $mlh));
  }
}

@mixin headers(){
  h1 {
    @include poly-fluid-sizing('margin-bottom', ($dsk: 35px, $mob: 22px));
    @include sr;
    @include poly-fluid-sizing('font-size', ($dsk: 50px, $mob: 35px));
    @include poly-fluid-sizing('line-height', ($dsk: 81px, $mob: 42px));
    color: $abbey;
  }

  h2 {
    @include poly-fluid-sizing('margin-bottom', ($dsk: 35px, $mob: 22px));
    @include sr;
    @include poly-fluid-sizing('font-size', ($dsk: 35px, $mob:22px));
    @include poly-fluid-sizing('line-height', ($dsk: 45px, $mob: 30px));
    color: $abbey;
  }

  h3 {
    @include poly-fluid-sizing('margin-bottom', ($dsk: 35px, $mob: 22px));
    @include sr;
    @include poly-fluid-sizing('font-size', ($dsk: 35px, $mob:22px));
    @include poly-fluid-sizing('line-height', ($dsk: 45px, $mob: 30px));
    color: $hot-cinnamon;
  }

  h4 {
    @include poly-fluid-sizing('margin-bottom', ($dsk: 35px, $mob: 22px));
    @include sr;
    @include poly-fluid-sizing('font-size', ($dsk: 35px, $mob:22px));
    @include poly-fluid-sizing('line-height', ($dsk: 45px, $mob: 30px));
    color: $teal;
  }

  h5 {
    @include poly-fluid-sizing('margin-bottom', ($dsk: 35px, $mob: 22px));
    @include sr;
    @include poly-fluid-sizing('font-size', ($dsk: 28px, $mob:18px));
    @include poly-fluid-sizing('line-height', ($dsk: 38px, $mob: 26px));
    color: $abbey;
  }
}