.c-summary {

  $self: &;

  margin-bottom: 40px;
  box-shadow: 0px 3px 6px 0px rgba($black, 16%);
  background-color: $white;
  padding: 20px 30px 40px;

  &__title {
    margin-bottom: 25px;
    @include sr;
    font-size: 35px;
    line-height: 57px;
    color: $hot-cinnamon;
  }

  &__subtitle {
    margin-top: 10px;
    margin-bottom: 10px;
    @include mb;
    font-size: 16px;
    line-height: 29px;
    color: $teal;
  }

  &__detail {
    min-height: 50px;
    @include mr;
    font-size: 16px;
    line-height: 29px;
  }

  &__attrib {
    color: $abbey;

    &--error {
      color: $guardsman-red;
    }

  }

  &__value {
    color: $teal;

    &--discount {
      display: none;
    }

  }

  &__ruler {
    margin: 10px 0;
    padding: 0;
    height: 1px;
    background-color: rgba($abbey, 45%);
  }

  &__amount-box {
    display: none;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  &__amount-input {
    margin-right: 30px;
  }

  &__button {
    margin-top: 25px;
  }

  &__continue {
    margin-top: 20px;
    @include mr;
    font-size: 16px;
    line-height: 29px;
    text-decoration: none;
    color: $teal;
    &:hover {
      color: $teal;
    }
  }

  &__remove {
    margin-left: 10px;
    text-decoration: underline;
    color: $teal;
    &:hover {
      color: $teal;
    }
  }

  &__paypal {
    position: relative;
    top: 3px;
    margin-left: 10px;
  }

  &__wrap {
    white-space: normal;
  }

  &__nowrap {
    white-space: nowrap;
    width: calc(100% - 30px);
  }

  &--show-discount {
    #{ $self }__amount-box {
      display: block;
    }
    #{ $self }__value {
      &--discount {
        display: block;
      }
    }
  }

  // Responsive

  @media(max-width: $xl - 1){
    &__button {
      &--xl-mt-10 {
        margin-top: 10px;
      }
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    padding: 20px;

    &__button {
      &--md-mt-0 {
        margin-top: 0px;
      }
    }

    &__button {
      &--md-mt-15 {
        margin-top: 15px;
      }
    }

    &__button {
      &--md-mt-30 {
        margin-top: 30px;
      }
    }

    &__paypal {
      width: 80px;
      margin-left: 5px;
    }

    &__detail {
      min-height: 0;
    }

    &__attrib {
      margin-bottom: 10px;
    }

    &__value {
      margin-bottom: 10px;
    }

    &__amount-input {
      margin-right: 5px;
    }

    &__continue {
      margin-top: 0;
    }

    &__donate-line {
      margin-bottom: 10px;
    }

  }

}