.s-totop {
  position: fixed;
  display: none;
  z-index: 100;
  bottom: 70px;
  right: 70px;

  &__block {
    display: block;
    border-radius: 25px;
    background-color: $teal;
    height: 50px;
    width: 250px;
    @include mb;
    font-size: 22px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &--show {
    display: block;
  }

  @media(max-width: 1800px){
    right: 10px;
    &__block {
      width: 50px;
      opacity: .9;
    }
    &__text {
      display: none;
    }
  }

}