.s-lmember {

  &__subtitle {
    margin-bottom: 40px;
    @include sr;
    font-size: 35px;
    line-height: 35px;
    color: $abbey;
  }

  &__detail {
    position: relative;
    margin-bottom: 20px;
    padding-left: 30px;
    @include mr;
    font-size: 20px;
    line-height: 24px;
    color: $abbey;

    br {
      display: none;
    }

    &--mb-35 {
      margin-bottom: 35px;
    }

    i {
      position: absolute;
      top: 0;
      left: 0;
      color: $teal;
    }

    a {
      text-decoration: none;
      color: $abbey;
    }

  }

  &__video {
    margin-bottom: 40px;
  }

  &__footer {
    justify-content: center;
  }

  &__socials {
    margin-bottom: 35px;
  }

  &__social {
    display: inline-block;
    margin-right: 30px;
    background-color: $hot-cinnamon;
    border-radius: 17px;
    width: 35px;
    height: 35px;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    color: $white;

    &:hover {
      color: $white;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    &__subtitle {
      margin-bottom: 20px;
      font-size: 22px;
      line-height: 36px;
    }
    &__detail {
      margin-bottom: 10px;
      padding-left: 25px;

      font-size: 14px;
      line-height: 24px;

      br {
        display: inline;
      }

      i {
        top: 5px;
      }

    }

    &__socials {
      margin-bottom: 20px;
    }

    &__social {
      margin-right: 20px;
      &:hover {
        color: $white;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    &__video {
      margin-bottom: 30px;
    }

  }

}