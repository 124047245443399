.c-select {

  $self: &;

  &__header {
    text-decoration: none;
  }

  &__title {
    margin-right: 15px;
    @include mr;
    font-size: 18px;
    line-height: 22px;
    color: $abbey;
  }
  &__action {
    line-height: 22px;
    color: $abbey;
  }
  &__body {
    position: relative;
    display: none;
  }
  &__list {
    position: absolute;
    z-index: 10;
    top: 22px;
    left: 0;
    min-width: 100%;
    box-shadow: 0px 3px 6px 0px rgba($black, 16%);
    background-color: $white;
    padding: 5px 20px 5px;

    &--min-w-360 {
      min-width: 360px;
      @media(max-width: $lg - 1){
        min-width: 160px;
      }
    }

    &--min-w-275 {
      min-width: 275px;
    }

    &--min-w-216 {
      min-width: 216px;
      @media(max-width: $lg - 1){
        min-width: 135px;
      }
    }

    &--min-w-220 {
      min-width: 220px;
    }

    &--align-right {
      left: auto;
      right: 0;
    }

  }
  &__input {
    display: none;
  }
  &__option {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $abbey;
    height: 56px;
    padding-left: 5px;
    padding-right: 5px;
    @include mr;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
    color: $abbey;
    &:last-child {
      border-bottom: none;
    }
    &--selected {
      @include mb;
    }
    &:hover {
      background-color: $abbey;
      color: $white;
    }
  }

  &--open {
    #{ $self }__body {
      display: block;
    }
    #{ $self }__action {
      transform: rotate(0.5turn);
    }
  }

  &--min-w-360 {
    min-width: 360px;
    @media(max-width: $lg - 1){
      min-width: 160px;
    }
  }

  &--min-w-275 {
    min-width: 275px;
  }

  &--min-w-216 {
    min-width: 216px;
    @media(max-width: $lg - 1){
      min-width: 135px;
    }
  }

  &--min-w-220 {
    min-width: 220px;
  }

  &--right {
    #{ $self }__header {
      justify-content: right;
      float: right; // Safari
    }
  }

  &--ml-25 {
    margin-left: 25px;
  }

  &--min-w-0- {
    min-width: 0 !important;
  }

  &--abs-right {
    position: absolute;
    right: 0;
  }

  // Responsive

  @media(max-width: $lg - 1){
    &--lg-filters {
      #{ $self }__title {
        margin-right: 5px;
        font-size: 12px;
        line-height: 15px;
      }
      #{ $self }__action {
        line-height: 15px;
      }
      #{ $self }__option {
        height: 38px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 12px;
        line-height: 15px;
      }
    }
    &__list {
      &--lg-min-w-180 {
        min-width: 180px;
      }
      &--lg-gather {
        left: auto;
        right: 0;
        min-width: 0;
        width: 160px;
      }
    }

    &--lg-min-w-85 {
      min-width: 85px;
    }
    &--lg-ml-10 {
      margin-left: 10px;
    }
  }
}