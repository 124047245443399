.c-file {

  $self: &;

  @include mr;
  font-size: 16px;
  line-height: 25px;
  color: $abbey;

  &__input {
    display: none;
  }
  &__title {
    margin-bottom: 3px;
  }
  &__button {
    border-radius: 3px;
    background-color: $white;
    width: 110px;
    height: 25px;
    cursor: pointer;
    text-align: center;
    color: $abbey;
  }
  &__value {
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
  }

  &--dark {
    #{ $self }__button {
      background-color: $abbey;
      color: $white;
    }
  }

}