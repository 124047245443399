.s-connected {
  background-color: $linen;
  background-image: url('/static/img/chat.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  padding-top: 30px;
  padding-bottom: 30px;

  &__title {
    display: inline-block;
    @include sr;
    font-size: 35px;
    line-height: 57px;
    color: $hot-cinnamon;
  }

  &__socials {
    display: inline-block;
  }

  // Mobile

  @media(max-width: $md - 1){
    background-position: bottom right;
    &__title {
      margin-bottom: 15px;
      font-size: 22px;
      line-height: 30px;
      text-align: center;
    }
  }

}