.c-button {

  display: inline-block;
  text-align: center;
  text-decoration: none;
  box-shadow: none;
  border: none;
  @include mr;

  &--mtb-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &--mb-25 {
    margin-bottom: 25px;
  }

  &--mb-30 {
    margin-bottom: 30px;
  }

  &--mb-40 {
    margin-bottom: 40px;
  }

  &--corner-6 {
    border-radius: 6px;
  }

  &--corner-26 {
    border-radius: 26px;
  }

  &--height-40 {
    height: 40px;
    line-height: 40px;
  }

  &--height-43 {
    height: 43px;
    line-height: 43px;
  }

  &--height-51 {
    height: 51px;
    line-height: 51px;
  }

  &--height-56 {
    height: 56px;
    line-height: 56px;
  }

  &--center {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
  }

  &--center-left {
    display: inline-flex;
    justify-content: start;
    align-items: center;
    line-height: normal;
    padding-left: 32px;
  }

  &--view {
    position: relative;
    padding-right: 56px;
    &:after {
      position: absolute;
      right: 32px;
      @include mb;
      font-size: 18px;
      line-height: 18px;
      color: $sherpa-blue;
      content: 'View';
    }
  }

  &--width-100 {
    width: 100px;
  }

  &--width-115 {
    width: 115px;
  }

  &--width-154 {
    width: 154px;
  }

  &--width-177 {
    width: 177px;
  }

  &--width-212 {
    width: 212px;
  }

  &--width-274 {
    width: 274px;
  }

  &--width-300 {
    width: 300px;
  }

  &--width-358 {
    width: 358px;
  }

  &--width-388 {
    width: 388px;
  }

  &--width-404 {
    width: 404px;
  }

  &--width-410 {
    width: 410px;
  }

  &--width-415 {
    width: 415px;
  }

  &--width-67p {
    width: 67%;
  }

  &--width-full {
    width: 100%;
  }

  &--weight-sr {
    @include sr;
  }

  &--weight-mb {
    @include mb;
  }

  &--size-18 {
    font-size: 18px;
  }

  &--size-22 {
    font-size: 22px;
  }

  &--size-30 {
    font-size: 30px;
  }

  &--size-35 {
    font-size: 35px;
  }

  &--back-teal {
    background-color: $teal;
  }

  &--back-hot-cinnamon {
    background-color: $hot-cinnamon;
  }

  &--back-neptune {
    background-color: $neptune;
  }

  &--color-abbey {
    color: $abbey;
    &:hover {
      color: $abbey;
    }
  }

  &--color-white {
    color: $white;
    &:hover {
      color: $white;
    }
  }

  &--opacity-25 {
    opacity: .25;
  }

  &--hover-teal {
    transition: background-color .2s linear;
    &:hover {
      background-color: $teal;
    }
  }

  &--hover-hot-cinnamon {
    transition: background-color .2s linear;
    &:hover {
      background-color: $hot-cinnamon;
    }
  }

  // Responsive

  @media(max-width: $xl - 1){
    &--width-xl-80 {
      width: 80%;
    }
  }

  @media(max-width: $lg - 1){
    &--mb-40 {
      margin-bottom: 20px;
    }
    &--mb-lg-0 {
      margin-bottom: 0;
    }
    &--mt-lg-10 {
      margin-top: 10px;
    }
    &--width-lg-full {
      width: 100%;
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    &--md-mb-10 {
      margin-bottom: 10px;
    }
    &--height-md-30 {
      height: 30px;
      line-height: 30px;
    }
    &--height-md-40 {
      height: 40px;
      line-height: 40px;
    }
    &--height-md-45 {
      height: 45px;
      line-height: 45px;
    }
    &--corner-md-15 {
      border-radius: 15px;
    }
    &--size-md-14 {
      font-size: 14px;
    }
    &--size-md-22 {
      font-size: 22px;
    }
    &--width-md-65 {
      width: 65px;
    }
    &--width-md-115 {
      width: 115px;
    }
    &--width-md-full {
      width: 100%;
    }
    &--width-md-full-60 {
      width: calc(100% - 60px);
    }
  }

  @media(max-width: 435px){
    &--width-435-full {
      width: 100%;
    }
  }

}