.s-cookies {
  position: fixed;
  display: none;
  z-index: 1000;
  bottom: 30px;
  width: 1137px;
  left: calc(50% - (1137px/2));

  border-radius: 16px;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  background-color: $white;
  padding: 16px;

  &.show{
    display: flex;
  }

  .statement{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .text{
      @include mr;
      font-size: 16px;

      p{
        margin: 0;

        a{
          color: $hot-cinnamon;
        }

      }

      @media(max-width: 991px){
        margin: 0 0 15px 0;
        text-align: center;
      }

    }

    .buttons{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      .btn{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        width: 335px;
        color: $white;
        @include sr;
        font-size: 35px;
        background-color: $teal;
        border-radius: 6px;
        border: 3px solid $teal;

        &:first-child{
          margin: 0 20px 0 0;

          @media(max-width: 767px){
            margin: 0 0 15px 0;
          }

        }

        &.inverted{
          color: $teal;
          background-color: $white;
        }

      }

      @media(max-width: 767px){
        flex-direction: column;
      }

    }

    @media(max-width: 991px){
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

  }

  .method{

    .text{
      @include mr;
      font-size: 16px;

      p{
        margin: 0;
      }

    }

    .buttons{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      .btn{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        width: 335px;
        color: $white;
        @include sr;
        font-size: 35px;
        background-color: $teal;
        border-radius: 6px;
        border: 3px solid $teal;

        &:first-child{
          margin: 0 20px 0 0;
        }

      }

    }

  }

  .manage{
    display: none;

    h2{
      @include sr;
      font-size: 35px;
      color: $abbey;
      margin: 0 0 40px 0;
    }

    p{
      @include mr;
      font-size: 20px;
      color: $abbey;
      margin: 0;
    }

    h3{
      @include sr;
      font-size: 27px;
      color: $abbey;
      margin: 40px 0 35px 0;
    }

    label{
      @include ml;
      font-size: 20px;
      color: $abbey;
      margin: 25px 40px 0 0;
      display: inline-flex;
      align-items: center;

      input{
        height: 26px;
        width: 26px;
        margin: 0 12px 0 0;
      }

    }

    button{
      margin: 40px 0 0 0;
      background-color: $teal;
      width: 335px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include sr;
      font-size: 35px;
      color: $white;
      border: none;
      border-radius: 6px;
    }

  }

  @media(max-width: 1167px){
    width: calc(100% - 30px);
    left: 15px;
  }

}