.c-preset {

  $self: &;

  &__option {
    display: none;
    &:checked {
      &+#{ $self }__label {
        background-color: $hot-cinnamon;
      }
    }
  }

  &__label {
    border-radius: 6px;
    background-color: $teal;
    height: 46px;
    width: 100%;
    @include mb;
    font-size: 18px;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
    color: $white;
  }

  &__value {
    display: none;
  }

}
