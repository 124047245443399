.c-post {
  display: block;
  margin-bottom: 40px;
  text-decoration: none;
  box-shadow: 0px 3px 6px 0px rgba($black, 16%);
  border-radius: 6px;
  background-color: $white;
  padding: 23px 20px 20px;

  &__title {
    margin-bottom: 10px;
    @include mb;
    font-size: 20px;
    line-height: 35px;
    color: $abbey;
  }

  &__meta {
    white-space: nowrap;
  }

  &__date {
    margin-bottom: 10px;
    margin-right: 15px;
    @include mr;
    font-size: 16px;
    line-height: 35px;
    color: $abbey;

    i {
      color: $teal;
    }

  }

  &__count {
    margin-bottom: 10px;
    @include mr;
    font-size: 16px;
    line-height: 35px;
    color: $abbey;

    i {
      color: $hot-cinnamon;
    }
  }

  &__lead {
    @include mr;
    font-size: 20px;
    line-height: 35px;
    color: $abbey;

    @include headers;

    &--limit {
      height: 105px;
      text-overflow:ellipsis;
      overflow:hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: normal;

    }
  }

  &__read-more {
    @include mb;
    font-size: 20px;
    line-height: 35px;
    color: $teal;
  }

  @media(max-width: $xl - 1){
    &__meta {
      margin-left: auto;
    }
    &__first-line {
      flex-direction: column-reverse;
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    margin-bottom: 20px;
    padding: 10px 15px 10px;

    &__date {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 16px;
    }

    &__count {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 16px;
    }

    &__title {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 17px;
    }

    &__lead {
      font-size: 14px;
      line-height: 22px;
    }

    &__read-more {
      font-size: 14px;
      line-height: 22px;
    }

  }

}
