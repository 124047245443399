.c-rate {
  &__input {
    display: none;
  }
  &__stars {
    font-size: 20px;
    line-height: 29px;
    text-decoration: none;
    white-space: nowrap;

    a {
      text-decoration: none;
      color: $hot-cinnamon;

      &:hover {
        color: $hot-cinnamon;
      }
    }
  }
}