.s-registered {
  padding-bottom: 55px;
  background-color: $wild-sand;

  &__content {
    @include mr;
    font-size: 16px;
    line-height: 40px;
    color: $teal;
  }

  &__rights {
    @include mr;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
    color: $abbey;

    &:hover {
      color: $abbey;
    }
  }

  &__br {
    display: none;
  }

  // Responsive

  @media(max-width: $lg - 1){
    &__content {
      line-height: 20px;
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    padding-bottom: 25px;
    &__regs {
      margin-bottom: 30px;
    }
    &__content {
      font-size: 14px;
      line-height: 22px;
    }
    &__rights {
      font-size: 14px;
      line-height: 22px;

      &--calm {
        margin-top: 25px;
      }
    }
    &__br {
      display: inline;
    }
  }

}