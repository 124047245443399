.s-crumb {
  border-bottom: 1px solid $abbey;
  height: 55px;

  &__list {
    margin-bottom: 0;
    @include mr;
    font-size: 18px;
    line-height: 22px;
    color: $teal;

    li {
      display: inline-block;
    }
  }

  &__item {
    text-decoration: none;
    color: $abbey;

    &:after {
      display: inline-block;
      margin-left: 23px;
      margin-right: 16px;
      width: 13px;
      height: 11px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('/static/img/breadcrumb.png');
      content: '';
    }

    &:hover {
      color: $abbey;
    }
  }

  // Mobile

  @media(max-width: $md - 1){

    border-top: 6px solid $teal;
    background-color: $alabasta;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;

    &__list {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 15px;
    }
    &__item {
      &:after {
        position: relative;
        top: 1px;
        display: inline-block;
        margin-left: 6px;
        margin-right: 0px;
      }
    }
  }

}