.c-cprod {

  margin-bottom: 35px;
  border-bottom: 1px solid rgba($abbey, 45%);

  &__image {
    margin-bottom: 35px;
    border-radius: 6px;
    width: 100%
  }

  &__info {
    font-size: 20px;
    line-height: 29px;
  }

  &__name {
    margin-bottom: 15px;
    @include mr;
    color: $abbey;
  }

  &__price-wrap {
    margin-bottom: 15px;
    @include mb;
    color: $hot-cinnamon;
  }

  &__size {
    margin-bottom: 15px;
    @include mr;
    color: $abbey;
  }

  &__quantity {
    margin-bottom: 15px;
    @include mr;
    color: $abbey;
  }

  &__remove {
    margin-bottom: 35px;
    @include mr;

    a {
      text-decoration: none;
      color: $teal;
      &:hover {
        color: $teal;
      }
    }
  }

  &:last-child {
    margin-bottom: 10px;
    border-bottom: none;
  }

  // Mobile

  @media(max-width: $md - 1){
    &__info {
      font-size: 12px;
      line-height: 15px;
    }
    &__row {
      --bs-gutter-x: 12px;
    }
    &__name {
      margin-bottom: 10px;
    }
    &__price-wrap {
      margin-bottom: 10px;
    }
    &__size {
      margin-bottom: 10px;
    }
    &__quantity {
      margin-bottom: 10px;
    }
  }

}