.c-option {
  display: block;
  margin-bottom: 35px;
  border-radius: 6px;
  background-color: $teal;
  padding: 30px;
  text-decoration: none;
  text-align: center;
  color: $white;

  &:hover {
    color: $white;
  }

  &__image {
    margin-bottom: 25px;
    height: 100px;
  }

  &__detail {
    @include mb;
    font-size: 18px;
    line-height: 26px;
  }

  &--back-hot-cinnamon {
    background-color: $hot-cinnamon;
  }

  // Mobile

  @media(max-width: $md - 1){
    margin-bottom: 10px;
    &__detail {
      font-size: 14px;
      line-height: 18px;
    }
  }

}
