h1, h2, h3, h4, h5, h6 {
  &.inherit {
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
}