.c-evmeta {

  @include mr;
  font-size: 20px;
  line-height: 24px;
  color: $abbey;

  &__item {
    position: relative;
    margin-bottom: 40px;
    padding-left: 35px;

    i {
      position: absolute;
      left: 0;
      margin-right: 15px;
      color: $teal;
    }
    &--date {
      min-width: 215px;
    }
    &--time {
      min-width: 145px;
    }
    &--local {
      min-width: 290px
    }
    &--ticket {
      min-width: 145px;
      @include mb;
      color: $teal;
    }
  }

  // Mobile

  @media(max-width: $md - 1){

    font-size: 18px;
    line-height: 22px;

    &__item {
      margin-bottom: 10px;
    }

    &__item--local {
      display: block;
      width: 100%;
    }

    &__item--ticket {
      display: block;
      width: 100%;
    }

    &__item--target {
      display: block;
      margin-bottom: 20px;
      width: 100%;
    }

    &__item--md-mb-20 {
      margin-bottom: 20px;
    }

  }

}