.s-mmenu {

  $self: &;

  position: relative;
  z-index: 100;
  display: none;
  @include mb;
  font-size: 14px;
  line-height: 22px;

  &--show {
    display: block;
  }

  &__menu {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 3px 12px 0px rgba(0,0,0,0.32);
    width: 100%;
    background-color: $white;

    &:after {
      position: absolute;
      top: -20px;
      right: 8px;
      width: 39px;
      height: 22px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';

      background-image: url('/static/img/mmenu_burger-arrow.png');
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('/static/img/mmenu_burger-arrow@2x.png');
      }
    }
  }

  &__lvl1-wrapper {
    &--open {
      #{ $self }__lvl1-arrow {
        transform: rotate(180deg);
      }
      #{ $self }__lvl2-list {
        display: block;
      }
    }
  }

  &__lvl1-list {
    margin-bottom: 15px;
  }

  &__lvl1-item {
    position: relative;
    border-bottom: 1px solid $linen;
    padding: 9px 40px 9px 22px;
  }

  &__lvl1-title {
    text-decoration: none;
    color: $hot-cinnamon;
    &:hover {
      color: $hot-cinnamon;
    }
  }

  &__lvl1-arrow {
    position: absolute;
    right: 20px;
    text-decoration: none;
    color: $hot-cinnamon;
    &:hover {
      color: $hot-cinnamon;
    }
  }

  &__lvl2-wrapper {
    &--open {
      #{ $self }__lvl2-arrow {
        transform: rotate(180deg);
      }
      #{ $self }__lvl3-list {
        display: block;
      }
    }
  }

  &__lvl2-list {
    display: none;
    margin-bottom: 0;
  }

  &__lvl2-item {
    position: relative;
    border-bottom: 1px solid $linen;
    background-color: $hot-cinnamon;
    padding: 9px 40px 9px 22px;
  }

  &__lvl2-title {
    text-decoration: none;
    color: $white;
    &:hover {
      color: $white;
    }
  }

  &__lvl2-arrow {
    position: absolute;
    right: 20px;
    text-decoration: none;
    color: $white;
    &:hover {
      color: $white;
    }
  }

  &__lvl3-wrapper {
    &--open {
      #{ $self }__lvl3-arrow {
        transform: rotate(180deg);
      }
      #{ $self }__lvl4-list {
        display: block;
      }
    }
  }

  &__lvl3-list {
    display: none;
    margin-bottom: 0;
  }

  &__lvl3-item {
    position: relative;
    border-bottom: 1px solid $white;
    background-color: $linen;
    padding: 9px 40px 9px 22px;
  }

  &__lvl3-title {
    text-decoration: none;
    color: $hot-cinnamon;
    &:hover {
      color: $hot-cinnamon;
    }
  }

  &__lvl3-arrow {
    position: absolute;
    right: 20px;
    text-decoration: none;
    color: $hot-cinnamon;
    &:hover {
      color: $hot-cinnamon;
    }
  }

  &__lvl4-list {
    display: none;
    margin-bottom: 0;
  }

  &__lvl4-item {
    position: relative;
    border-bottom: 1px solid $linen;
    background-color: $white;
    padding: 9px 40px 9px 44px;
  }

  &__lvl4-title {
    text-decoration: none;
    color: $hot-cinnamon;
    &:hover {
      color: $hot-cinnamon;
    }
  }

  &__lvl4-arrow {
    position: absolute;
    right: 20px;
    text-decoration: none;
    color: $hot-cinnamon;
    &:hover {
      color: $hot-cinnamon;
    }
  }

  &__language {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    border-radius: 6px;
    border: 1px solid $abbey;
    background-color: $white;
    width: calc(100vw - 40px);
    height: 43px;
    overflow: hidden;
  }

  &__currency {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    border-radius: 6px;
    border: 1px solid $abbey;
    width: calc(100vw - 40px);
  }

  &__logging {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    border-radius: 6px;
    background-color: $hot-cinnamon;
    width: calc(100vw - 40px);
    height: 40px;
    text-decoration: none;
    @include mb;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    color: $white;

    &:hover {
      color: $white;
    }

  }

  // Mobile

  @media(max-width: $md - 1){
    &__menu {
      display: block;
    }
  }

  // Responsive

  @media(min-width: $sm){
    &__menu {
      &:after {
        right: calc(50vw - 262px);
      }
    }
  }
}
