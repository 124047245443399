.s-role {
  &__forgotten {
    @include mr;
    font-size: 20px;
    line-height: 24px;
    color: $teal;
    &:hover {
      color: $teal;
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    &__forgotten {
      font-size: 14px;
      line-height: 18px;
    }
  }

}