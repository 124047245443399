.s-banner {

  .s-banner__image {
    width: 100%;
    height: 0;
    padding-bottom: 30.729166%;

    background-position: bottom, center;
    background-repeat: no-repeat, no-repeat;
    background-size: 100% auto, cover;
  }

  .owl-dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 8%;
    height: 14px;
    margin-top: -7px;
  }

  .owl-dot {

    display: inline-block;
    margin-left: 3px;
    margin-right: 3px;
    border: none;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: $nobel !important;

    &.active {
      background-color: $hot-cinnamon !important;
    }

  }

  @media(max-width: $md - 1){
    border-top: 6px solid $teal;

    .owl-dot {
      margin-left: 1px;
      margin-right: 1px;
      width: 6px;
      height: 6px;
    }

  }

}
