.c-opportunity {
  margin-bottom: 40px;
  text-decoration: none;

  &__data {
    background-color: $teal;
    border-radius: 6px;
    width: 290px;
    padding: 25px 30px 6px
  }

  &__info {
    width: calc(100% - 325px);
  }

  &__label {
    margin-bottom: 6px;
    @include mb;
    font-size: 18px;
    line-height: 22px;
    color: $white;
  }

  &__value {
    margin-bottom: 22px;
    @include mr;
    font-size: 18px;
    line-height: 22px;
    color: $white;
  }

  &__title {
    margin-bottom: 15px;
    @include mb;
    font-size: 20px;
    line-height: 35px;
    color: $abbey;
  }

  &__lead {
    @include mr;
    font-size: 18px;
    line-height: 28px;
    color: $abbey;

    @include headers;

    p {
      margin-bottom: 28px;
      &:last-child {
        margin-bottom: 0;
      }
    }

  }

  // Responsive

  @media(max-width: $lg - 1){
    &__data {
      margin-bottom: 10px;
      width: 100%;
      padding: 15px 15px 0px
    }
    &__info {
      width: 100%;
    }
    &__lead {
      margin-bottom: 10px;
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    margin-bottom: 20px;
    &__label {
      font-size: 14px;
      line-height: 18px;

    }
    &__title {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 22px;
    }
    &__value {
      font-size: 14px;
      line-height: 18px;

    }
    &__lead {
      font-size: 14px;
      line-height: 22px;
      p {
        margin-bottom: 22px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}