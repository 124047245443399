.s-menu--cache {
  display: none;
}

.s-menu {

  $self: &;

  border-bottom: 6px solid $teal;

  &__sa-wrap {
    position: relative;

    &:hover {
      #{ $self }__sa-area {
        display: block;
      }
    }
  }

  &__sb-wrap {
    position: relative;
    &:hover {
      #{ $self }__sb-area {
        display: flex;
      }
    }
  }

  &__sa-item {
    display: inline-block;
    border-radius: 6px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: $hot-cinnamon;
    width: 177px;
    height: 40px;
    @include mb;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    color: $white;

    &:hover {
      color: $white;
    }

    &--current {
      background-color: $teal;
      &:hover {
        background-color: $teal;
      }
    }

  }

  &__sa-drop {
    position: absolute;
    width: 100%;
    padding-top: 8px;
    z-index: 10;
  }

  &__sa-area {
    position: relative;
    display: none;
    //width: js;
  }

  &__sb-wrap {
    &:hover {
      #{$self}__sb-item {
        background-color: $hot-cinnamon;
        color: $white;
      }
    }
  }

  &__sb-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    box-shadow: 6px 5px 11px -3px rgba(0,0,0,0.52);
    border-radius: 6px;
    background-color: $white;
    width: 207px;
    min-height: 50px;
    padding: 14px 22px;
    @include mb;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
    color: $hot-cinnamon;

    &--current {
      background-color: $teal;
      color: $white;
      &:hover {
        color: $white !important;
      }
    }

    &:hover {
      color: $hot-cinnamon;
    }
  }

  &__sb-drop {
    position: absolute;
    top: 0;
    left: 235px;
    right: 0;
    z-index: 20;
  }

  &__sb-area {
    display: none;
    box-shadow: 6px 5px 11px -3px rgba(0,0,0,0.52);
    border-radius: 6px;
    background-color: $hot-cinnamon;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    max-height: 357px;
  }

  &__sc-item {
    position: relative;
    display: block;
    margin-left: 0;
    margin-right: 0;
    width: 363px;
    text-decoration: none;

    &--current {
      background-color: $teal;
      color: $white;
    }

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 14px;
      right: 14px;
      border-top: 1px solid rgba($white, 25%);
    }

    &:first-child {
      &:before {
        border-top: none;
      }
    }

  }


  &__sc-item-inner {
    display: flex;
    align-items: center;
    min-height: 50px;
    padding-left: 28px;
    padding-right: 28px;
    @include mb;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &__sc-wrap {
    position: relative;
    &:before {
      position: absolute;
      left: 14px;
      right: 14px;
      border-top: 1px solid rgba($white, 25%);
      content: '';
    }
    #{ $self }__sc-item {
      position: relative;
      &:after {
        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -12px;
        font-family: "Font Awesome 5 Free";
        font-size: 18px;
        font-weight: 900;
        content: '\f078';
        color: $white;
      }
    }
    #{ $self }__sc-item-inner {
      padding-right: 32px;
    }

    &:hover {
      #{ $self }__sc-item {
        &:after {
          content: '\f077';
        }
      }
      #{ $self }__sc-list {
        //display: block;
        visibility: visible;
        transition: visibility 0s, opacity 0s linear;
        transition-delay: 0.2s;
        opacity: 1;
      }
    }
  }

  &__sc-list {
    position: absolute;
    z-index: 10;
    //display: none;
    visibility: hidden;

    opacity: 0;
    background-color: $linen;
  }

  &__sd-item {
    position: relative;
    display: block;
    margin-left: 0;
    margin-right: 0;
    width: 363px;
    text-decoration: none;

    &--current {
      background-color: $teal;
      #{ $self }__sd-item-inner {
        color: $white;
        &:hover {
          color: $white;
        }
      }
    }

    &:before{
      position: absolute;
      content: '';
      top: 0;
      left: 14px;
      right: 14px;
      border-top: 1px solid rgba($hot-cinnamon, 25%);
    }

    &:first-child {
      &:before{
        border-top: none;
      }
    }

  }

  &__sd-item-inner {
    display: flex;
    align-items: center;
    min-height: 50px;
    padding-left: 28px;
    padding-right: 28px;
    @include mb;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
    color: $hot-cinnamon;

    &:hover {
      color: $hot-cinnamon;
    }
  }

  &__sa-wrap {
    &--open-right {
      #{ $self }__sb-drop {
        position: absolute;
        z-index: -5;
        //position: relative;
        //top: -58px;
        left: -390px;
      }
      #{ $self }__sb-area {
        width: 363px;
      }
    }
  }

  // Responsive

  @media(max-width: $xxl - 1){
    &__sa-item {
      width: 151px;
    }
    &__sb-area {
      flex-wrap: nowrap;
      max-height: unset;
    }
  }

  @media(max-width: $xl - 1){
    &__sa-item {
      width: 125px;
    }
  }

  @media(max-width: $lg - 1){
    &__sa-item {
      width: auto;
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    display: none;
  }

}
