.s-access {

  $self: &;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .s-access__controls {

    position: absolute;
    display: none;
    top: 0;
    right: 0;
    z-index: 100;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    border-radius: 6px;
    background-color: $white;
    width: 360px;

    &--active {
      display: block;
    }
  }

  &__header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: $atol;
    height: 80px;
    padding-left: 35px;
    padding-right: 35px;
    @include mb;
    font-size: 22px;
    line-height: 80px;
    text-transform: uppercase;
    color: $white;
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    display: block;
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url('/static/img/close.png');
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url('/static/img/close@2x.png');
    }
  }

  &__toggle {
    display: block;
    height: 60px;
    border-bottom: 1px solid $abbey;
    padding-left: 20px;
    padding-right: 20px;
    @include mb;
    font-size: 20px;
    line-height: 20px;
    text-decoration: none;
    color: $abbey;
    &:hover {
      color: $abbey;
    }

    &--active {
      background-color: $abbey;
      color: $white;
      &:hover {
        color: $white;
      }

      #{$self}__icon {
        background-position: top;
      }
    }
  }

  &__icon {
    display: block;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% auto;

    &--blk-wht {
      background-image: url('/static/img/access_1.png');
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('/static/img/access_1@2x.png');
      }
    }
    &--contrast {
      background-image: url('/static/img/access_2.png');
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('/static/img/access_2@2x.png');
      }
    }
    &--readable {
      background-image: url('/static/img/access_3.png');
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('/static/img/access_3@2x.png');
      }
    }

  }

  &__label {
    margin-left: 20px;
  }

  &__sizes {
    border-bottom: 1px solid $abbey;
  }

  &__size {
    display: block;
    border-right: 1px solid $abbey;
    width: 33.3333%;
    height: 60px;
    @include mb;
    text-decoration: none;
    color: $abbey;

    &:last-child {
      border-right: none;
    }

    &:hover {
      color: $abbey;
    }

    &--active {
      background-color: $abbey;
      color: $white;
      &:hover {
        color: $white;
      }
    }

    &--1 {
      font-size: 20px;
      line-height: 20px;
    }
    &--2 {
      font-size: 24px;
      line-height: 24px;
    }
    &--3 {
      font-size: 28px;
      line-height: 28px;
    }
  }

  &__cancel {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    height: 50px;
    @include mr;
    font-size: 16px;
    line-height: 50px;
    text-decoration: none;
    color: $abbey;
    &:hover {
      color: $abbey;
    }
  }

  &__cross {
    display: inline-block;
    margin-left: 10px;
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url('/static/img/cancel.png');
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url('/static/img/cancel@2x.png');
    }
  }

  @media(max-width: 1539px){
    &__adjustment {
      position: relative;
    }
  }

  @media(max-width: $md - 1){
    &__adjustment {
      position: static;
    }
  }

  @media(max-width: 360px){
    .s-access__controls {
      width: 100%;
    }
  }

}
