.s-cartupdate {

  position: fixed;
  display: none;
  right: 50px;
  top: 50px;

  box-shadow: 0px 3px 6px 0px rgba($black, 16%);
  background-color: $white;
  padding: 18px 25px;

  &__close {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 20px;
    line-height: 20px;
    text-decoration: none;
    color: $abbey;

    &:hover {
      color: $abbey;
    }

  }

  &__title {
    margin-bottom: 20px;
    @include sr;
    font-size: 25px;
    line-height: 30px;
    color: $abbey;
  }

  &__content {
    margin-bottom: 5px;
    @include mr;
    font-size: 18px;
    line-height: 19px;
    color: $abbey;
  }

  &--show {
    display: block;
  }

}