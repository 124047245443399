$white:               #ffffff;
$blue:                #0000ff;

$hot-cinnamon:        #D86018;
$abbey:               #54585A;
$teal:                #00747B;
$black:               #000000;
$wild-sand:           #F6F6F6;
$aqua-haze:           #E5F1F1;
$linen:               #FAEEE6;
$neptune:             #7EB8BC;
$sherpa-blue:         #014A4E;
$guardsman-red:       #BC0000;
$alabasta:            #FAFAFA;
$nobel:               #B4B4B4;
$atol:                #07747B;
$milano-red:          #B40707;
$elephant:            #13454B;
$jungle-mist:         #A9D1CE;
$aqua-haze:           #E6F1F0;
