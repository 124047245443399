.c-extra {

  $self: &;

  &__block {
    display: none;
  }

  &__item {
    &--show {
      #{ $self }__block {
        display: block;
      }
    }
  }

}
