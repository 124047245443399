.s-section {

  &--border-top {
    border-top: 6px solid $teal;
  }

  &__title {
    margin-bottom: 50px;
    @include sr;
    font-size: 50px;
    line-height: 81px;
    color: $hot-cinnamon;

    &--mb-10 {
      margin-bottom: 10px;
    }

    &--mb-40 {
      margin-bottom: 40px;
    }

    &--abbey {
      color: $abbey;
    }

    &--teal {
      color: $teal;
    }

  }

  &__subtitle {
    position: relative;
    display: inline-block;
    margin-bottom: 35px;
    @include sr;
    font-size: 35px;
    line-height: 57px;
    color: $abbey;

    @include headers;

    &--wings {
      &:before {
        position: absolute;
        content: '';
        top: 50%;
        left: -53px;
        width: 35px;
        border-top: 1px solid $abbey;
      }

      &:after {
        position: absolute;
        content: '';
        top: 50%;
        right: -53px;
        width: 35px;
        border-top: 1px solid $abbey;
      }
    }

    &--mb-0 {
      margin-bottom: 0;
    }

    &--mb-10 {
      margin-bottom: 10px;
    }

    &--teal {
      color: $teal;
    }

  }

  &__subtitle-2 {
    margin-bottom: 20px;
    @include mb;
    font-size: 20px;
    line-height: 35px;
    color: $abbey;
  }

  &__lead {
    margin-bottom: 25px;
    @include mr;
    font-size: 30px;
    line-height: 40px;
    color: $abbey;

    &--mb-35 {
      margin-bottom: 35px;
    }

  }

  &__highlight {
    margin-bottom: 30px;
    @include mb;
    font-size: 30px;
    line-height: 45px;

    a {
      text-decoration: none;
      color: $hot-cinnamon;
      &:hover {
        color: $hot-cinnamon;
      }
    }

  }

  &__highlight-2 {
    margin: 0 0 40px;
    @include sr;
    font-size: 35px;
    line-height: 45px;
    color: $hot-cinnamon;
  }

  &__content {
    margin-bottom: 50px;
    @include mr;
    font-size: 20px;
    line-height: 35px;
    color: $abbey;

    @include headers;

    p { margin-bottom: 35px; }

    a:not(.c-button) {
      text-decoration: underline;
      color: $teal;
      &:hover {
        color: $teal;
      }
    }

    &--mb-35 {
      margin-bottom: 35px;
    }

    &--mb-10 {
      margin-bottom: 10px;
    }

    &--a-abbey {
      a {
        text-decoration: none;
        color: $abbey;
        &:hover {
          color: $abbey;
        }
      }
    }
  }

  &__quote {
    margin-bottom: 40px;
    @include mb;
    font-size: 20px;
    line-height: 40px;
    color: $abbey;

    &:before {
      font-size: 180%;
      content: '“';
    }
    &:after {
      font-size: 180%;
      content: '”';
    }
    &--teal {
      font-size: 24px;
      color: $teal;
    }
    &--hot-cinnamon {
      color: $hot-cinnamon;
    }
  }

  &__hr {
    margin: 0 0 40px;
    padding: 0;
    background-color: $abbey;
    height: 2px;
    opacity: 1;

    &--teal {
      background-color: $teal;
    }
    &--hot-cinnamon {
      background-color: $hot-cinnamon;
    }

    &--left {
      width: 40%;
    }

    &--right {
      margin-left: 60%;
      width: 40%;
    }

  }

  &__all {
    @include mb;
    font-size: 19px;
    line-height: 23px;
    text-decoration: none;
    color: $teal;
    &:hover {
      color: $teal;
    }
  }

  &--bg-wild-sand {
    background-color: $wild-sand;
  }

  &--curve-lower-1 {
    background-image: url('/static/img/curve_lower_1.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-top: -1px; // Fix home page gap below carousel //
  }

  &--pt-12 {
    padding-top: 12px;
  }

  &--pt-50 {
    padding-top: 50px;
  }

  &--pt-100 {
    padding-top: 100px;
  }

  &--pb-20 {
    padding-bottom: 20px;
  }

  &--pb-40 {
    padding-bottom: 40px;
  }

  &--pb-50 {
    padding-bottom: 50px;
  }

  &--pb-80 {
    padding-bottom: 80px;
  }

  &--pb-100 {
    padding-bottom: 100px;
  }

  &--pb-160 {
    padding-bottom: 160px;
  }

  &--pb-255 {
    padding-bottom: 255px;
  }

  // Mobile

  @media(max-width: $md - 1){

    &--md-border-top {
      border-top: 6px solid $teal;
    }

    &--md-pt-20 {
      padding-top: 20px;
    }

    &--md-pt-30 {
      padding-top: 30px;
    }

    &--md-pb-20 {
      padding-bottom: 20px;
    }

    &--md-pb-30 {
      padding-bottom: 30px;
    }

    &--pt-md-20 {
      padding-top: 20px;
    }

    &--pb-md-40 {
      padding-bottom: 40px;
    }

    &__title {
      margin-bottom: 20px;
      font-size: 35px;
      line-height: 42px;
    }

    &__lead {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 22px;
    }

    &__content {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 22px;
      p { margin-bottom: 22px; }
    }

    &__subtitle {
      margin-bottom: 25px;
      font-size: 22px;
      line-height: 30px;
    }
    &__all {
      font-size: 14px;
      line-height: 22px;
    }

    &__highlight {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 22px;
    }

    &__subtitle-2 {
      margin-bottom: 0px;
      font-size: 14px;
      line-height: 22px;
    }

    &__highlight-2 {
      margin: 0 0 20px;
      font-size: 22px;
      line-height: 30px;
    }

    &__quote {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 22px;
    }

    &__hr {
      margin-bottom: 20px;
    }

    &__quote {
      &--teal {
        font-size: 17px !important;
      }
    }

  }
}