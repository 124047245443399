.c-article {
  display: block;
  margin-bottom: 40px;
  text-decoration: none;

  &__thumbnail {
    margin-bottom: 15px;
    border-radius: 6px;
    width: 100%;
  }

  &__title {
    margin-bottom: 10px;
    @include mb;
    font-size: 18px;
    line-height: 22px;
    color: $abbey;
  }

  &__date {
    margin-right: 10px;
    @include mr;
    font-size: 18px;
    line-height: 22px;
    color: $abbey;
  }

  &__more {
    @include mb;
    font-size: 18px;
    line-height: 22px;
    color: $teal;
  }

  // Mobile

  @media(max-width: $md - 1){
    margin-bottom: 20px;

    &__more {
      float: right;
    }

  }

}