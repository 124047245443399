.c-stars {
  font-size: 20px;
  line-height: 29px;
  text-decoration: none;
  color: $hot-cinnamon;

  &:hover {
    color: $hot-cinnamon;
  }

  // Mobile

  @media(max-width: $md - 1){
    font-size: 12px;
    line-height: 22px;
  }

}