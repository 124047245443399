.s-fmenu {
  background-color: $wild-sand;

  &__link {
    margin-bottom: 26px;
    @include mr;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
    color: $abbey;

    &:hover {
      color: $abbey;
    }
  }

  // Responsive

  @media(max-width: $lg - 1){
    padding-bottom: 20px;
    &__link {
      margin-bottom: 5px;
      line-height: 20px;
    }
  }

  // Mobile

  @media(max-width: $md - 1){

  }

}