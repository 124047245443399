.c-social {
  display: inline-block;
  border-radius: 17px;
  background-color: $white;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 18px;
  text-align: center;
  color: $hot-cinnamon;
  text-decoration: none;

  &:hover {
    color: $hot-cinnamon;
  }

  &--connected {
    border-radius: 28px;
    background-color: $hot-cinnamon;
    width: 56px;
    height: 56px;
    font-size: 29px;
    line-height: 56px;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &--inverse {
    background-color: $hot-cinnamon;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  // Mobile
  @media(max-width: $md - 1){
    &--connected {
      margin: 6px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
    }
  }

}
