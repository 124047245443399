.s-body {

  $self: &;

  &--grayscale {
    filter: grayscale(100%);
  }

  &__shop {
    #{ $self }__shop-section {
      padding-top: 0;
      background-color: $white;
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    &__shop {
      #{ $self }__shop-section {
        padding-bottom: 0;
      }
    }
  }

}