.c-blknews {
  margin-bottom: 44px;
  display: block;
  text-decoration: none;

  &__image {
    margin-bottom: 25px;
    border-radius: 6px;
    width: 100%;
  }

  &__info {
    font-size: 18px;
    line-height: 22px;
  }

  &__date {
    margin-right: 28px;
    @include mr;
    color: $abbey;
  }

  &__more {
    @include mb;
    color: $teal;
  }

  // Responsive

  @media(max-width: $lg - 1){
    &__date {
      margin-right: 2px;
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    margin-bottom: 25px;
    &__image {
      margin-bottom: 10px;
    }
    &__more {
      float: right;
    }
  }

}