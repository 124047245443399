.c-smenu--cache {
  display: none;
}

.c-smenu {

  $self: &;

  background-color: $white;
  margin-bottom: 65px;

  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $abbey;
    padding-left: 5px;
    padding-right: 10px;
    @include mb;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &__active {
    margin-right: 5px;
    width: 5px;
    min-width: 5px;
  }

  &__menu-list {
    display: none;
  }

  &__menu-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $abbey;
    padding-left: 5px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &__menu-name {
    margin-right: 15px;
    @include mb;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
  }

  &__menu-navi {
    margin-left: auto;
  }

  &__wrap--open {
    >#{ $self }__menu-row {
      #{ $self }__menu-navi {
        transform: rotate(0.5turn);
      }
    }
    >#{ $self }__menu-list {
      display: block;
    }
  }

  // Level 1 Colours

  &__menu-row {
    background-color: $white;
  }
  &__menu-name, &__item, &__menu-navi {
    color: $abbey;
    &:hover {
      color: $abbey;
    }
  }
  &__active {
    &--activated {
      background-color: $elephant;
    }
  }

  // Level 2 Colours

  &__menu-list {
    background-color: $teal;
    #{$self}__menu-row {
     background-color: $teal;
    }
    #{$self}__menu-name, #{$self}__item, #{$self}__menu-navi {
      color: $white;
      &:hover {
        color: $white;
      }
    }
    #{$self}__active {
      &--activated {
        background-color: $white;
      }
    }
  }

  // Level 3 Colours

  &__menu-list {
    #{$self}__menu-list {
      background-color: $jungle-mist;
      #{$self}__menu-row {
        background-color: $jungle-mist;
      }
      #{$self}__menu-name, #{$self}__item, #{$self}__menu-navi {
        color: $abbey;
        &:hover {
          color: $abbey;
        }
      }
      #{$self}__active {
        &--activated {
          background-color: $abbey;
        }
      }
    }
  }

  // Level 4 Colours

  &__menu-list {
    #{$self}__menu-list {
      #{$self}__menu-list {
        background-color: $aqua-haze;
        #{$self}__menu-row {
          background-color: $aqua-haze;
        }
        #{$self}__menu-name, #{$self}__item, #{$self}__menu-navi {
          color: $abbey;
          &:hover {
            color: $abbey;
          }
        }
        #{$self}__active {
          &--activated {
            background-color: $abbey;
          }
        }
      }
    }
  }

  // Open States

  &__wrap--open {
    >#{ $self }__menu-row {
      background-color: $elephant !important;
      #{$self}__menu-name, #{$self}__item, #{$self}__menu-navi {
        color: $white;
        &:hover {
          color: $white;
        }
      }
      #{ $self }__active {
        &--activated {
          background-color: $white;
        }
      }
    }
  }
}
