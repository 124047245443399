.s-rating {
  &__number {
    @include mr;
    font-size: 20px;
    line-height: 35px;
    color: $abbey;
  }

  &__item {
    margin-bottom: 20px;
    border-radius: 6px;
    box-shadow: 0px 3px 6px 0px rgba($black, 16%);
    padding: 20px;
    &:last-child {
      margin-bottom: 20px;
    }
  }

  &__data {
    margin-bottom: 15px;
  }

  &__name {
    @include mb;
    font-size: 20px;
    line-height: 35px;
    color: $abbey;
  }

  &__date {
    margin-left: 25px;
    @include mr;
    font-size: 16px;
    line-height: 16px;
    color: $abbey;
    white-space: nowrap;

    i { color: $teal; }
  }

  &__content {
    @include mr;
    font-size: 20px;
    line-height: 29px;
    color: $abbey;

    p {
      margin-bottom: 29px;
      &:last-child {
        margin-bottom: 0;
      }
    }

  }

  &__left {
    margin-right: 20px;
  }

  // Responsive

  @media(max-width: $lg - 1){
    &__mob-1 {
      margin-bottom: 5px;
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    &__item {
      margin-bottom: 10px;
      padding: 15px;
    }

    &__content {
      font-size: 14px;
      line-height: 22px;
      p {
        margin-bottom: 22px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

  }

}