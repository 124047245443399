.c-search {

  $self: &;

  @include mr;
  font-size: 22px;
  line-height: 46px;
  width: 170px;

  transition: all linear .2s;

  &__text {
    position: relative;
    left: 25px;
    z-index: 5;
    //border: none;
    background: $white;
    border: 1px solid transparent;
    height: 46px;
    color: $abbey;
    padding-right: 0px;
    cursor: pointer;

    transition: all linear .2s;

  }
  &__input {
    background: $white;
    border: 1px solid transparent;
    border-right: none;
    border-left: none;
    height: 46px;
    pointer-events:none;

    transition: all linear .2s;

  }

  &__button {
    background: $white;
    border: 1px solid transparent;
    height: 46px;
    padding-left: 0px;
    pointer-events: none;
    transition: all linear .2s;

  }

  &--open {
    width: 298px;

    #{ $self }__text {
      left: 0px;
      border-top: 1px solid $abbey;
      border-bottom: 1px solid $abbey;
      border-left: 1px solid $abbey;
      padding-right: 12px;
      z-index: auto;
    }

    #{ $self }__input {
      border-top: 1px solid $abbey;
      border-bottom: 1px solid $abbey;
      pointer-events:auto;
    }

    #{ $self }__button {
      border-top: 1px solid $abbey;
      border-bottom: 1px solid $abbey;
      border-right: 1px solid $abbey;
      padding-left: 12px;
      pointer-events: auto;
    }

  }

  // Mobile

  @media(max-width: $md - 1){
    &--open {
      width: 253px;
    }
  }

}