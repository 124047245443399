.c-tdata {
  margin-bottom: 40px;

  &__block {
    margin-right: 60px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__label {
    margin-bottom: 10px;
    @include mb;
    font-size: 20px;
    line-height: 24px;
    color: $teal;
  }
  &__value {
    @include mr;
    font-size: 20px;
    line-height: 24px;
    color: $abbey;
  }

  // Responsive

  @media(max-width: $lg - 1){
    &__block {
      display: flex;
      justify-content: space-between;
      margin: 0;
    }
  }

  // Mobile

  @media(max-width: $lg - 1){
    margin-bottom: 20px;

    &__label {
      font-size: 14px;
      line-height: 18px;
    }
    &__value {
      font-size: 14px;
      line-height: 18px;
    }
  }

}
