@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

@font-face {font-family: "Sketchnote Square Regular";
  src: url("/static/webfonts/sketchnote-square.eot"); /* IE9*/
  src: url("/static/webfonts/sketchnote-square.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/static/webfonts/sketchnote-square.woff2") format("woff2"), /* chrome、firefox */
  url("/static/webfonts/sketchnote-square.woff") format("woff"), /* chrome、firefox */
  url("/static/webfonts/sketchnote-square.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("/static/webfonts/sketchnote-square.svg#Sketchnote Square Regular") format("svg"); /* iOS 4.1- */
}

@mixin sr {
  font-family: "Sketchnote Square Regular";
  font-weight: 400;
  font-style: normal;
}

@mixin ml {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin mr {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin mb {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
}

