.c-panel {

  margin-bottom: 24px;
  display: block;
  text-decoration: none;

  &__box {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    background-color: $aqua-haze;
  }

  &__contents {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__icon-outer {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 40%;
    height: 0;
    padding-bottom: 40%;
  }

  &__icon-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__icon {
    width: 100%;
  }

  &__info {
    margin-bottom: 6px;
    @include mb;
    font-size: 18px;
    line-height: 22px;
    color: $sherpa-blue;

    &--mb-20 {
      margin-bottom: 20px;
    }
  }

  // Mobile

  @media(max-width: $md - 1){

    &__info {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 18px;
 
      &--mb-20 {
        margin-bottom: 10px;
      }
    }

  }

}