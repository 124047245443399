.c-accordion {

  $self: &;

  margin-bottom: 10px;

  &__header {
    text-decoration: none;
  }

  &__title {
    margin-bottom: 30px;
    width: calc(100% - 30px);
    @include sr;
    font-size: 35px;
    line-height: 45px;
    color: $abbey;
  }
  &__action {
    position: relative;
    top: -12px;
    display: inline-block;
    width: 26px;
    height: 26px;
    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 11px;
      border-left-width: 4px;
      border-left-style: solid;
      border-left-color: $abbey;
      content: '';
    }
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 11px;
      border-bottom-width: 4px;
      border-bottom-style: solid;
      border-bottom-color: $abbey;
      content: '';
    }
  }

  &__body {
    display: none;
    margin-bottom: 30px;
    @include mr;
    font-size: 20px;
    line-height: 35px;
    color: $abbey;

    @include headers;

    a{
      text-decoration: none;
    }

    strong {
      @include mb;
    }

  }

  &--teal {
    #{ $self }__title {
      color: $teal;
    }
    #{ $self }__action {
      &:before {
        border-left-color: $teal;
      }
      &:after {
        border-bottom-color: $teal;
      }
    }
    #{ $self }__body {
      strong { color: $teal; }
    }
  }

  &--hot-cinnamon {
    #{ $self }__title {
      color: $hot-cinnamon;
    }
    #{ $self }__action {
      &:before {
        border-left-color: $hot-cinnamon;
      }
      &:after {
        border-bottom-color: $hot-cinnamon;
      }
    }
    #{ $self }__body {
      strong { color: $hot-cinnamon; }
    }
  }

  &--donations {
    #{ $self }__fold {
      margin-bottom: 20px;
    }
    #{ $self }__header {
      min-height: 56px;
      border-radius: 6px;
      background-color: $neptune;
      padding-left: 20px;
      padding-right: 20px;
    }
    #{ $self }__title {
      margin-bottom: 0;
      width: calc(100% - 120px);
      color: $white;
    }
    #{ $self }__action {
      width: 140px;
      @include mb;
      font-size: 18px;
      line-height: 28px;
      text-align: right;
      color: $sherpa-blue;
      margin-top: 20px;
      &:before {
        position: absolute;
        right: -3px;
        display: block;
        background-color: $neptune;
        border-left: none;

        content: 'Show Details';
        @media(max-width: $md - 1){
          content: 'Show';
        }

        @include mb;
        font-size: 18px;
        line-height: 28px;
        color: $sherpa-blue;
      }
      &:after {
        display: none;
      }
    }
  }

  &__fold {
    &--open {
      #{ $self }__body{
        display: block;
      }
      #{ $self }__action {
        &:before {
          content: none;
        }
      }
    }
  }

  // Mobile

  @media(max-width: $md - 1){
    &__title {
      margin-bottom: 10px;
      font-size: 22px;
      line-height: 30px;
    }
    &__action {
      top: -10px;
      left: -5px;
      width: 15px;
      height: 15px;
      &:before {
        top: 0;
        bottom: 0;
        left: 6px;
        border-left-width: 3px;
      }
      &:after {
        left: 0;
        right: 0;
        top: 6px;
        border-bottom-width: 3px;
      }
    }
    &__body {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 22px;
    }

    &--donations {
      #{ $self }__header {
        min-height: 45px;
        padding-left: 10px;
        padding-right: 10px;
      }
      #{ $self }__action {
        width: 160px;
        line-height: 16px;
        &:before {
          top: -6px;
        }
      }
    }

  }

}